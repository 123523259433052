import React, { forwardRef, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Grid, Button, Container, IconButton } from "@material-ui/core";
import { TextField } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import styled from "styled-components";
import {
  NumberFormatCustom,
  MenuItemPlanning,
  GridWrapper,
  CustomPopover,
} from "components";
import { Typography } from "@material-ui/core";
import { useForm } from "react-hook-form";
import { isFilledAllFields, convertToNumberWithoutCommas } from "utils/form";
import useMobileStyles from "../mobileStyles";
import { color } from "styles/color";
import _ from "lodash";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import InputAdornment from "@material-ui/core/InputAdornment";
import messages from "../../messages";
import { FormattedMessage } from "react-intl";
import MobileLanding from "../MobileLanding";
import EditIcon from "@material-ui/icons/Edit";

const MobilePlanningForm = forwardRef((props, ref) => {
  const {
    intl,
    handleSubmitForm,
    initialValues,
    resources,
    optionalResources,
    isLanding,
    handleStart,
  } = props;
  const theme = useTheme();
  const classes = useMobileStyles();
  const dropDownIcon = styled(ExpandMoreIcon)`
    && {
      fill: ${theme.palette.secondary.main};
      font-size: 2rem;
    }
  `;
  const [disabledInflation, setDisabledInflation] = useState(true);
  const [disabledGrowth, setDisabledGrowth] = useState(true);
  const [inflationRatePop, setinflationRatePop] = useState("");
  const [interestRatePop, setinterestRatePop] = useState("");

  const [dropdown, setDropdown] = useState({
    retirementCountry: initialValues?.country
      ? initialValues?.country
      : "select",
    currency: initialValues?.currency ? initialValues?.currency : "select",
    currentAge: initialValues?.currentAge
      ? initialValues?.currentAge
      : "select",
    retirementAge: initialValues?.retirementAge
      ? initialValues?.retirementAge
      : "select",
    lifeStyle: initialValues?.lifestyle ? initialValues?.lifestyle : "select",
  });

  const editInflation = () => {
    setDisabledInflation(!disabledInflation);
  };

  const editGrowth = () => {
    setDisabledGrowth(!disabledGrowth);
  };

  const {
    handleSubmit,
    register,
    getValues,
    watch,
    setValue,
    formState: { errors, isDirty },
  } = useForm({
    defaultValues: initialValues,
  });
  const watchAllFields = watch();

  const onSubmit = (data) => {
    handleSubmitForm(data);
  };

  const COUNTRIES = [];
  const CURRENCIES = [];
  const purchasePowerParity =
    _.find(resources, { key: "nc_purchase_power_parity" })?.value?.data || [];
  const LIFESTYLE =
    _.find(resources, { key: "nc_lifestyle_replacement" })?.value?.data || [];
  optionalResources.forEach((item) => {
    if (item.group === "goal_retirement_country") {
      purchasePowerParity.forEach((country) => {
        if (country.key === item.code) {
          COUNTRIES.push({
            value: item.en,
            label: item.en,
            inflationRate: country.inflationRate,
            interestRate: country.interestRate,
          });
        }
      });
    }
    if (item.group === "goal_currency") {
      CURRENCIES.push({
        value: item.code,
        label: item.en,
      });
    }
  });
  const countries = COUNTRIES.sort(function(a, b) {
    var nameA = a.label.toUpperCase();
    var nameB = b.label.toUpperCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });
  const currencies = CURRENCIES.sort(function(a, b) {
    var nameA = a.label.toUpperCase();
    var nameB = b.label.toUpperCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });
  const countryInflationRate = (
    countries.find((item) => item.value === getValues("country"))
      ?.inflationRate * 100
  ).toFixed(2);

  const countryInterestRate = (
    countries.find((item) => item.value === getValues("country"))
      ?.interestRate * 100
  ).toFixed(2);

  const ERROR_MESSAGE = {
    E001: intl.formatMessage(messages.error1),
    E002: intl.formatMessage(messages.error2),
    E003: intl.formatMessage(messages.error3, {
      currentAge: getValues("currentAge") * 1 + 7,
    }),
    E004: intl.formatMessage(messages.error4),
    E005: intl.formatMessage(messages.error5, {
      monthlyIncome:
        convertToNumberWithoutCommas(getValues("monthlyIncome")) * 1 - 1,
    }),
    E006: intl.formatMessage(messages.error6, {
      value:
        convertToNumberWithoutCommas(getValues("monthlyIncome")) * 1 -
        convertToNumberWithoutCommas(getValues("monthlyExpense")) * 1,
    }),
    E007: intl.formatMessage(messages.error7),
    E008: intl.formatMessage(messages.error8, {
      min: (countryInflationRate * 0.5).toFixed(2),
      max: (countryInflationRate * 1.5).toFixed(2),
    }),
    E009: intl.formatMessage(messages.error9, {
      min: (countryInterestRate * 0.5).toFixed(2),
      max: (countryInterestRate * 1.5).toFixed(2),
    }),
    E010: intl.formatMessage(messages.error10),
    E011: intl.formatMessage(messages.error11),
  };

  useEffect(() => {
    if (disabledInflation) {
      setinflationRatePop(
        (
          countries.find((item) => item.value === dropdown.retirementCountry)
            ?.inflationRate * 100
        ).toFixed(2)
      );
    }
    if (disabledGrowth) {
      setinterestRatePop(
        (
          countries.find((item) => item.value === dropdown.retirementCountry)
            ?.interestRate * 100
        ).toFixed(2)
      );
    }
  }, [watchAllFields]);

  return (
    <>
      {isLanding ? (
        <MobileLanding onStart={handleStart} />
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Container style={{ padding: 0 }}>
            <GridWrapper
              theme={theme}
              style={{
                backgroundColor: "#C2C9D5",
                padding: "0 16px",
                height: `${window.innerHeight}px`,
              }}
              container
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={12}>
                <Typography
                  variant="body1"
                  color="secondary"
                  className={classes.titleText}
                >
                  <FormattedMessage {...messages?.title} />
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <Typography
                  variant="body1"
                  color="secondary"
                  className={classes.defaultText}
                >
                  I would like to retire in
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <TextField
                  variant="outlined"
                  select
                  name="country"
                  className={classes.selectTextField}
                  value={dropdown.retirementCountry}
                  error={!!errors.country}
                  inputRef={ref}
                  SelectProps={{
                    classes: {
                      select:
                        dropdown.retirementCountry === "select"
                          ? classes.placeholderTextField
                          : classes.selectTextField,
                    },
                    style: {
                      minWidth: 134,
                      minHeight: 26,
                    },
                    IconComponent: dropDownIcon,
                  }}
                  {...register("country", {
                    required: true,
                    onChange: (e) =>
                      setDropdown({
                        ...dropdown,
                        retirementCountry: e.target.value,
                      }),
                  })}
                >
                  <MenuItemPlanning
                    key={"select"}
                    value={"select"}
                    theme={theme}
                    disabled
                  >
                    Country
                  </MenuItemPlanning>
                  {countries.map((country) => (
                    <MenuItemPlanning
                      key={country.key}
                      value={country.value}
                      theme={theme}
                      height={4}
                    >
                      {country.label}
                    </MenuItemPlanning>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={7}>
                <Typography
                  variant="body1"
                  color="secondary"
                  className={classes.defaultText}
                >
                  and would like to save in
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <TextField
                  select
                  variant="outlined"
                  name="currency"
                  value={dropdown.currency}
                  className={classes.selectTextField}
                  defaultValue={""}
                  error={!!errors.currency}
                  inputRef={ref}
                  SelectProps={{
                    classes: {
                      select:
                        dropdown.currency === "select"
                          ? classes.placeholderTextField
                          : classes.selectTextField,
                    },
                    style: {
                      minWidth: 134,
                      minHeight: 26,
                    },
                    IconComponent: dropDownIcon,
                  }}
                  {...register("currency", {
                    required: true,
                    onChange: (e) =>
                      setDropdown({
                        ...dropdown,
                        currency: e.target.value,
                      }),
                  })}
                >
                  <MenuItemPlanning
                    key={"select"}
                    value={"select"}
                    theme={theme}
                    height={4}
                    disabled
                  >
                    Currency
                  </MenuItemPlanning>
                  {currencies.map((currency) => (
                    <MenuItemPlanning
                      key={currency.key}
                      value={currency.value}
                      theme={theme}
                      height={4}
                    >
                      {currency.label}
                    </MenuItemPlanning>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="body1"
                  color="secondary"
                  className={classes.defaultText}
                >
                  I am {"  "}
                  <TextField
                    variant="outlined"
                    select
                    name="currentAge"
                    className={classes.selectTextField}
                    defaultValue={""}
                    value={dropdown.currentAge}
                    error={!!errors.currentAge}
                    inputRef={ref}
                    SelectProps={{
                      classes: {
                        select:
                          dropdown.currentAge === "select"
                            ? classes.placeholderTextField
                            : classes.selectTextField,
                      },
                      style: {
                        maxWidth: 134,
                        minHeight: 26,
                      },
                      IconComponent: dropDownIcon,
                    }}
                    {...register("currentAge", {
                      required: true,
                      onChange: (e) =>
                        setDropdown({
                          ...dropdown,
                          currentAge: e.target.value,
                        }),
                    })}
                  >
                    <MenuItemPlanning
                      key={"select"}
                      value={"select"}
                      theme={theme}
                      height={4}
                      disabled
                    >
                      Age
                    </MenuItemPlanning>
                    {Array.from({ length: 76 - 18 + 1 }, (
                      _,
                      i // 76 is the oldest age in the dropdown, 18 is the youngest age in the dropdown
                    ) => (
                      <MenuItemPlanning key={i} value={i + 18} theme={theme}>
                        {i + 18}
                      </MenuItemPlanning>
                    ))}
                  </TextField>
                  {"  "}years old and would
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <Typography
                  variant="body1"
                  color="secondary"
                  className={classes.defaultText}
                >
                  like to retire when I am
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <TextField
                  variant="outlined"
                  select
                  name="retirementAge"
                  className={classes.selectTextField}
                  defaultValue={""}
                  value={dropdown.retirementAge}
                  error={!!errors.retirementAge}
                  inputRef={ref}
                  SelectProps={{
                    classes: {
                      select:
                        dropdown.retirementAge === "select"
                          ? classes.placeholderTextField
                          : classes.selectTextField,
                    },
                    style: {
                      minWidth: 134,
                      minHeight: 26,
                    },
                    IconComponent: dropDownIcon,
                  }}
                  {...register("retirementAge", {
                    required: true,
                    onChange: (e) =>
                      setDropdown({
                        ...dropdown,
                        retirementAge: e.target.value,
                      }),
                  })}
                >
                  <MenuItemPlanning
                    key={"select"}
                    value={"select"}
                    theme={theme}
                    height={40}
                    disabled
                  >
                    Age
                  </MenuItemPlanning>
                  {Array.from(
                    {
                      length:
                        getValues("currentAge") > 0
                          ? 83 - getValues("currentAge") - 7 + 1 // 76 is the oldest age in the dropdown, 7 is the age difference between the current age and the retirement age
                          : 0,
                    },
                    (_, i) => (
                      <MenuItemPlanning
                        key={i}
                        value={getValues("currentAge") + 7 + i}
                        theme={theme}
                      >
                        {getValues("currentAge") + 7 + i}
                      </MenuItemPlanning>
                    )
                  )}
                </TextField>
              </Grid>
              <Grid item xs={7}>
                <Typography
                  variant="body1"
                  color="secondary"
                  className={classes.defaultText}
                >
                  My monthly income is{" "}
                  {`${
                    dropdown.currency === "select" ? "" : dropdown.currency
                  } `}
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <TextField
                  variant="outlined"
                  name="monthlyIncome"
                  defaultValue={
                    initialValues?.monthlyIncome
                      ? initialValues.monthlyIncome
                      : ""
                  }
                  placeholder="1,000"
                  error={!!errors.monthlyIncome}
                  className={classes.customInput}
                  inputProps={{
                    autoComplete: "off",
                    className: classes.customInput,
                    style: {
                      color: errors.monthlyIncome
                        ? theme.palette.error.main
                        : "",
                      padding: theme.spacing(0, 1.7),
                      fontWeight: watchAllFields.monthlyIncome
                        ? "bold"
                        : "normal",
                    },
                    inputMode: "numeric",
                  }}
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                    endAdornment: errors.monthlyIncome ? (
                      <>
                        <CustomPopover errorMessage={ERROR_MESSAGE.E004} />
                      </>
                    ) : null,
                  }}
                  inputRef={ref}
                  {...register("monthlyIncome", {
                    required: true,
                    validate: () => {
                      const income = convertToNumberWithoutCommas(
                        getValues("monthlyIncome")
                      );
                      if (income < 500 || income > 9999999999) {
                        return ERROR_MESSAGE.E004;
                      }
                    },
                  })}
                />
              </Grid>
              <Grid item xs={7}>
                <Typography
                  variant="body1"
                  color="secondary"
                  className={classes.defaultText}
                >
                  and I spend{" "}
                  {`${
                    dropdown.currency === "select" ? "" : dropdown.currency
                  } `}
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <TextField
                  variant="outlined"
                  name="monthlyExpense"
                  className={classes.customInput}
                  defaultValue={
                    initialValues?.monthlyExpense
                      ? initialValues.monthlyExpense
                      : ""
                  }
                  inputProps={{
                    autoComplete: "off",
                    className: classes.customInput,
                    style: {
                      color: errors.monthlyExpense
                        ? theme.palette.error.main
                        : "",
                      padding: theme.spacing(0, 1.7),
                      fontWeight: watchAllFields.monthlyExpense
                        ? "bold"
                        : "normal",
                    },
                    inputMode: "numeric",
                  }}
                  error={!!errors.monthlyExpense}
                  inputRef={ref}
                  placeholder="1,000"
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                    endAdornment: errors.monthlyExpense ? (
                      <>
                        <CustomPopover
                          errorMessage={ERROR_MESSAGE.E005}
                          id={"monthlyExpense"}
                        />
                      </>
                    ) : null,
                  }}
                  {...register("monthlyExpense", {
                    required: true,
                    validate: () => {
                      const income = convertToNumberWithoutCommas(
                        getValues("monthlyIncome")
                      );
                      const expense = convertToNumberWithoutCommas(
                        getValues("monthlyExpense")
                      );
                      if (expense > income - 1) {
                        return ERROR_MESSAGE.E005;
                      }
                    },
                  })}
                />
              </Grid>
              <Grid item xs={7}>
                <Typography
                  variant="body1"
                  color="secondary"
                  className={classes.defaultText}
                >
                  So far I have saved{" "}
                  {`${
                    dropdown.currency === "select" ? "" : dropdown.currency
                  } `}
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <TextField
                  variant="outlined"
                  placeholder="1,000"
                  defaultValue={
                    initialValues?.currentSavings
                      ? initialValues.currentSavings
                      : ""
                  }
                  error={!!errors.currentSavings}
                  className={classes.customInput}
                  inputProps={{
                    autoComplete: "off",
                    className: classes.customInput,
                    style: {
                      color: errors.currentSavings
                        ? theme.palette.error.main
                        : "",
                      padding: theme.spacing(0, 1.7),
                      fontWeight: watchAllFields.currentSavings
                        ? "bold"
                        : "normal",
                    },
                    inputMode: "numeric",
                  }}
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                    endAdornment: errors.currentSavings ? (
                      <>
                        <CustomPopover
                          errorMessage={ERROR_MESSAGE.E007}
                          id={"currentSavings"}
                        />
                      </>
                    ) : null,
                  }}
                  inputRef={ref}
                  {...register("currentSavings", {
                    required: true,
                    validate: () => {
                      const saved = convertToNumberWithoutCommas(
                        getValues("currentSavings")
                      );
                      if (saved > 9999999999999) {
                        return ERROR_MESSAGE.E007;
                      }
                    },
                  })}
                />
              </Grid>
              <Grid item xs={7}>
                <Typography
                  variant="body1"
                  color="secondary"
                  className={classes.defaultText}
                >
                  and will save{" "}
                  {`${
                    dropdown.currency === "select" ? "" : dropdown.currency
                  } `}
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <TextField
                  variant="outlined"
                  name="plannedSavings"
                  placeholder="1,000"
                  defaultValue={
                    initialValues?.plannedSavings
                      ? initialValues.plannedSavings
                      : ""
                  }
                  className={classes.customInput}
                  inputProps={{
                    autoComplete: "off",
                    className: classes.customInput,
                    style: {
                      color: errors.plannedSavings
                        ? theme.palette.error.main
                        : "",
                      padding: theme.spacing(0, 1.7),
                      fontWeight: watchAllFields.plannedSavings
                        ? "bold"
                        : "normal",
                    },
                    inputMode: "numeric",
                  }}
                  error={!!errors.plannedSavings}
                  inputRef={ref}
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                    endAdornment: errors.plannedSavings ? (
                      <>
                        <CustomPopover
                          errorMessage={ERROR_MESSAGE.E006}
                          id={"plannedSavings"}
                        />
                      </>
                    ) : null,
                  }}
                  {...register("plannedSavings", {
                    required: true,
                    validate: () => {
                      const income = convertToNumberWithoutCommas(
                        getValues("monthlyIncome")
                      );
                      const expense = convertToNumberWithoutCommas(
                        getValues("monthlyExpense")
                      );
                      const planed = convertToNumberWithoutCommas(
                        getValues("plannedSavings")
                      );
                      if (planed > income - expense || planed < 0) {
                        return ERROR_MESSAGE.E006;
                      }
                    },
                  })}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="body1"
                  color="secondary"
                  className={classes.defaultText}
                >
                  monthly for my retirement. When I retire I would
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <Typography
                  variant="body1"
                  color="secondary"
                  className={classes.defaultText}
                >
                  like my lifestyle to be
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <TextField
                  variant="outlined"
                  select
                  name="lifestyle"
                  className={classes.selectTextField}
                  defaultValue={""}
                  value={dropdown.lifeStyle}
                  error={!!errors.lifestyle}
                  inputRef={ref}
                  SelectProps={{
                    classes: {
                      select:
                        dropdown.lifeStyle === "select"
                          ? classes.placeholderTextField
                          : classes.selectTextField,
                    },
                    style: {
                      minWidth: 134,
                      minHeight: 26,
                    },
                    IconComponent: dropDownIcon,
                  }}
                  {...register("lifestyle", {
                    required: true,
                    onChange: (e) =>
                      setDropdown({
                        ...dropdown,
                        lifeStyle: e.target.value,
                      }),
                  })}
                >
                  <MenuItemPlanning
                    key={"select"}
                    value={"select"}
                    theme={theme}
                    height={4}
                    disabled
                  >
                    Lifestyle
                  </MenuItemPlanning>
                  {LIFESTYLE.map((lifestyle) => (
                    <MenuItemPlanning
                      key={lifestyle.key}
                      value={lifestyle.description}
                      theme={theme}
                      height={4}
                    >
                      {lifestyle.description}
                    </MenuItemPlanning>
                  ))}
                </TextField>
              </Grid>
              <Grid
                item
                container
                xs={12}
                style={{
                  backgroundColor: "#DDE4E3",
                  padding: "20px 20px 20px 25px",
                }}
              >
                <Grid item container xs={12} align="center">
                  <Grid item xs={12}>
                    <Typography
                      variant="body1"
                      className={classes.defaultText}
                      algin="center"
                      color="secondary"
                      style={{ paddingBottom: "10px", fontWeight: "bold" }}
                    >
                      Assumed values
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={5}>
                  <Typography
                    variant="body1"
                    color="secondary"
                    className={classes.defaultText}
                  >
                    Inflation
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <TextField
                    variant="outlined"
                    name="inflationRate"
                    disabled={disabledInflation}
                    value={inflationRatePop === "NaN" ? 0 : inflationRatePop}
                    error={!!errors.inflationRate}
                    className={classes.customInput}
                    inputProps={{
                      autoComplete: "off",
                      className: classes.customInput,
                      style: {
                        color: errors.inflationRate
                          ? theme.palette.error.main
                          : "",
                        padding: theme.spacing(0, 1.7),
                        fontWeight: "bold",
                      },
                      inputMode: "numeric",
                    }}
                    InputProps={{
                      inputComponent: NumberFormatCustom,
                      endAdornment: errors.inflationRate ? (
                        <>
                          <CustomPopover
                            errorMessage={ERROR_MESSAGE.E008}
                            id={"inflationRate"}
                          />
                        </>
                      ) : (
                        <InputAdornment position="end">
                          <Typography
                            variant="h1"
                            className={classes.defaultText}
                            color="secondary"
                          >
                            %
                          </Typography>
                        </InputAdornment>
                      ),
                    }}
                    inputRef={ref}
                    {...register("inflationRate", {
                      required: true,
                      validate: () => {
                        const inflationRate =
                          (getValues("inflationRate") * 1).toFixed(2) * 1;
                        const min =
                          (countryInflationRate * 1 * 0.5).toFixed(2) * 1;
                        const max =
                          (countryInflationRate * 1 * 1.5).toFixed(2) * 1;
                        if (inflationRate < min || inflationRate > max) {
                          return ERROR_MESSAGE.E008;
                        }
                      },
                      onChange: (e) => {
                        setinflationRatePop(
                          convertToNumberWithoutCommas(e.target.value)
                        );
                      },
                    })}
                  />
                  <IconButton
                    color="primary"
                    onClick={editInflation}
                    size="small"
                  >
                    <EditIcon fontSize="small" />
                  </IconButton>
                </Grid>

                <Grid item xs={5} style={{ paddingTop: 5 }}>
                  <Typography
                    variant="body1"
                    color="secondary"
                    className={classes.defaultText}
                  >
                    Growth rate
                  </Typography>
                </Grid>
                <Grid item xs={7} style={{ paddingTop: 5 }}>
                  <TextField
                    variant="outlined"
                    name="growthRate"
                    disabled={disabledGrowth}
                    value={interestRatePop === "NaN" ? 0 : interestRatePop}
                    error={!!errors.growthRate}
                    className={classes.customInput}
                    inputProps={{
                      autoComplete: "off",
                      className: classes.customInput,
                      style: {
                        color: errors.growthRate
                          ? theme.palette.error.main
                          : "",
                        padding: theme.spacing(0, 1.7),
                        fontWeight: "bold",
                      },
                      inputMode: "numeric",
                    }}
                    InputProps={{
                      inputComponent: NumberFormatCustom,
                      endAdornment: errors.growthRate ? (
                        <>
                          <CustomPopover
                            errorMessage={ERROR_MESSAGE.E009}
                            id={"inflationRate"}
                          />
                        </>
                      ) : (
                        <InputAdornment position="end">
                          <Typography
                            variant="h1"
                            className={classes.defaultText}
                            color="secondary"
                          >
                            %
                          </Typography>
                        </InputAdornment>
                      ),
                    }}
                    inputRef={ref}
                    {...register("growthRate", {
                      required: true,
                      validate: () => {
                        const interestRate =
                          (getValues("growthRate") * 1).toFixed(2) * 1;
                        const min = (countryInterestRate * 0.5).toFixed(2) * 1;
                        const max = (countryInterestRate * 1.5).toFixed(2) * 1;
                        if (interestRate < min || interestRate > max) {
                          return ERROR_MESSAGE.E009;
                        }
                      },
                      onChange: (e) =>
                        setinterestRatePop(
                          convertToNumberWithoutCommas(e.target.value)
                        ),
                    })}
                  />
                  <IconButton color="primary" onClick={editGrowth} size="small">
                    <EditIcon fontSize="small" />
                  </IconButton>
                </Grid>
              </Grid>
              <Grid
                item
                container
                xs={12}
                justifyContent="center"
                style={{ paddingBottom: 20 }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={!isDirty || !isFilledAllFields(watchAllFields)}
                  style={{
                    height: 41,
                    borderRadius: 30,
                    textTransform: "none",
                    padding: "0px 30px",
                    backgroundColor:
                      !isDirty || !isFilledAllFields(watchAllFields)
                        ? color.grey
                        : theme.palette.primary.main,
                  }}
                >
                  <Typography
                    variant="body1"
                    className={classes.defaultText}
                    style={{
                      color:
                        !isDirty || !isFilledAllFields(watchAllFields)
                          ? "rgba(0, 0, 0, 0.26)"
                          : color.white,
                      fontWeight: "bold",
                    }}
                  >
                    <FormattedMessage {...messages.riskQuestionnaire} />
                  </Typography>
                </Button>
              </Grid>
            </GridWrapper>
          </Container>
        </form>
      )}
    </>
  );
});

MobilePlanningForm.propTypes = {
  handleSubmitForm: PropTypes.func,
  inputData: PropTypes.object,
  initialValues: PropTypes.object,
  resources: PropTypes.object,
  optionalResources: PropTypes.object,
  getConverterAction: PropTypes.func,
  isMobileSize: PropTypes.bool,
};
export default MobilePlanningForm;
