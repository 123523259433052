import { defineMessages } from 'react-intl';

export const scope = 'app.containers.App';

export default defineMessages({
  appUpdatingMessage: {
    id: `${scope}.appUpdatingMessage`,
    defaultMessage: `Please wait. We are applying new updates...`,
  },
  appReloading: {
    id: `${scope}.appReloading`,
    defaultMessage: `Reloading app...`,
  },
  defaultMessage: {
    id: `${scope}.defaultMessage`,
    defaultMessage: `Default message`,
  },
});
