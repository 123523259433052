import { isNaN } from "lodash";
import { isBoolean, isNumber } from "lodash/lang";

export function isFieldEmpty(fieldValue) {
  return !fieldValue && !isBoolean(fieldValue) && !isNumber(fieldValue);
}

export const convertToNumberWithoutCommas = (value) => {
  if (!value) return 0;
  if (isNaN(Number(value.toString().replaceAll(",", "")))) {
    return value;
  } else {
    return Number(value.toString().replaceAll(",", ""));
  }
};

export const isFilledAllFields = (watchFields) => {
  const listValue = Object.values(watchFields);
  const listKey = Object.keys(watchFields);
  const watch = [];

  listValue.find((value, index) => {
    if (!value) {
      watch[index] = false;
    } else {
      watch[index] = true;
    }
    
    if (value === "select") {
      watch[index] = false;
    }

    if (listKey[index] === "inflationRate" || listKey[index] === "growthRate") {
      watch[index] = true;
    }
  });

  if (watch.includes(false)) {
    return false;
  } else {
    return true;
  }
};
