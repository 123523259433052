import { createStyles, makeStyles } from "@material-ui/core/styles";
import { color } from "styles/color";
const useStyles = makeStyles((theme) =>
  createStyles({
    selectTextField: {
      padding: theme.spacing(1, 0, 0, 1),
      fontSize: "18pt",
      fontWeight: 300,
      color: theme.palette.secondary.main,
      backgroundColor: "#fff",
      minHeight: 25,
      "@media (min-width: 1800px)": {
        fontSize: "18pt",
        minWidth: theme.spacing(3),
        padding: theme.spacing(1, 0, 0, 1),
      },
      "&:focus": {
        backgroundColor: "#fff",
        borderRadius: 4,
      },
    },
    placeholderTextField: {
      padding: theme.spacing(1, 0, 0, 1),
      fontSize: "18pt",
      fontWeight: 300,
      color: '#A2BFDD',
      backgroundColor: "#fff",
      minHeight: 25,
      "@media (min-width: 1800px)": {
        fontSize: "18pt",
        minWidth: theme.spacing(3),
        padding: theme.spacing(1, 0, 0, 1),
      },
      "&:focus": {
        backgroundColor: "#fff",
        borderRadius: 4,
      },
    },
    selectTextFieldQuestion: {
      padding: "20px 0 0px 10px",
      fontSize: 30,
      fontWeight: 300,
      color: theme.palette.secondary.main,
      minWidth: 100,
      backgroundColor: "#fff",
      "@media (min-width: 1800px)": {
        fontSize: "3rem",
      },
    },
    customInput: {
      padding: theme.spacing(0, 0, 0, 1),
      fontSize: "18pt",
      color: theme.palette.secondary.main,
      fontWeight: 300,
      minHeight: 35,
      maxWidth: 200,
      backgroundColor: "#fff",
      borderRadius: "4px",
      // "@media (max-width: 768px)": {
      //   fontSize: theme.spacing(3),
      //   maxWidth: 170,
      // },
      "&:focus": {
        backgroundColor: "#fff",
      },
    },
    customWidth: {
      width: 150,
      margin: "0 8px 0 0",
      "& > .MuiInput-underline.Mui-error:after": {
        border: `1px solid ${theme.palette.error.main}`,
        height: theme.spacing(5),
        marginLeft: theme.spacing(-1),
        "@media (max-width: 768px)": {
          height: theme.spacing(4),
          maxWidth: 160,
        },
      },
    },
    customWidthSelect: {
      minWidth: 70,
      margin: theme.spacing(0, 1),
      paddingBottom: theme.spacing(1),
      "& > .MuiInput-underline.Mui-error:after": {
        border: `1px solid ${theme.palette.error.main}`,
        height: theme.spacing(5),
        marginLeft: theme.spacing(-1),
        "@media (max-width: 768px)": {
          height: theme.spacing(4),
        },
      },
    },
    customInputBox: {
      padding: 0,
      fontSize: "18pt",
      color: theme.palette.secondary.main,
      fontWeight: 300,
      minWidth: 50,
      maxWidth: 80,
      height: 27,
      borderRadius: 4,
    },
    submitButton: {
      margin: theme.spacing(2, 0, 2, 0),
      marginTop: "20px",
      borderRadius: 32,
      textTransform: "none",
    },
    defaultText: {
      fontSize: "18pt",
      color: theme.palette.secondary.main,
    },
    chartContainer: {
      backgroundColor: "#ECEEEF",
      padding: "16px 0px",
      maxWidth: "435px",
    },
    chartBox: {
      padding: 8,
      backgroundColor: "#fff",
      borderRadius: "32px",
      margin: "auto",
      width: "70%",
    },
    customTextSize: {
      fontSize: "13pt",
    },
    nomalText: {
      fontSize: "14px",
    },
    simulateImgSize: {
      width: "40px",
      height: "40px",
    },
    customTextQuestionnaire: {
      fontSize: "10pt",
      fontWeight: 600,
    },
    nextButton: {
      width: "100%",
      marginTop: 50,
      height: 50,
      borderRadius: 30,
      fontSize: 20,
      fontWeight: 700,
      textTransform: "none",
    },
    backButton: {
      width: "100%",
      marginTop: 10,
      height: 50,
      borderRadius: 30,
      fontSize: 20,
      fontWeight: 700,
      textTransform: "none",
    },
    summaryButton: {
      width: 380,
      height: 50,
      borderRadius: 32,
      fontSize: 20,
      fontWeight: 700,
      textTransform: "none",
      textAlign: "left",
    },
    questionImageSize: {
      width: '100%',
    },
    menuItem: {
      fontSize: "14px",
      padding: "0px 0px 0px 10px",
    },
    textfield: {
      margin: "0 8px 0 0",
      backgroundColor: color.white,
      borderRadius: "4px",
    },
  })
);
export default useStyles;