import AppBar from '@material-ui/core/AppBar';
import { makeStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { color } from 'styles/color';
import TabPanel from './components/TabPanel';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  customFlexContainer: {
    justifyContent: 'center',
  },
  headerAppBar: {
    background: 'none',
    color: theme.palette.primary.main,
    boxShadow: 'none',
    borderBottom: `1px solid ${theme.palette.primary.main}`,
  },
  customTab: {
    textTransform: 'none',
    fontSize: '2.8rem',
    fontWeight: 300,
    '@media (max-width: 768px)': {
      fontSize: '1.4rem',
    },
  },
  customActive: {
    '& > span': {
      backgroundColor: color.indicator,
      height: 5,
      borderRadius: 10,
    },
  },
}));

export default function HorizontalMenu({ data, handleTabs }) {
  const classes = useStyles();
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    handleTabs(newValue);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static" className={classes.headerAppBar}>
        <Tabs
          variant="fullWidth"
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example"
          classes={{
            flexContainer: classes.customFlexContainer,
            scroller: classes.customActive,
          }}>
          {data.map(item => (
            <Tab
              key={item.id}
              label={item.title}
              {...a11yProps(item.id)}
              className={classes.customTab}
            />
          ))}
        </Tabs>
      </AppBar>
      {data.map(item => (
        <TabPanel key={item.id} value={value} index={item.id}>
          {item.component}
        </TabPanel>
      ))}
    </div>
  );
}

HorizontalMenu.propTypes = {
  data: PropTypes.array,
  handleTabs: PropTypes.func,
};
