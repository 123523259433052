/**
 * The global state selectors
 */

import { createSelector } from "reselect";

const selectGlobal = (state) => state.get("global");

const selectRouter = (state) => state.get("router");

const makeSelectLocation = () =>
  createSelector(selectRouter, (routerState) =>
    routerState.get("location").toJS()
  );

const makeSelectLoading = () =>
  createSelector(selectGlobal, (globalState) => globalState.get("loading"));

const makeSelectLoadingMessage = () =>
  createSelector(selectGlobal, (globalState) =>
    globalState.get("loadingMessage").toJS()
  );

  const makeSelectAppUpdate = () =>
  createSelector(selectGlobal, (globalState) =>
    globalState.get("updateStatus")
  );

const makeSelectToken = () =>
  createSelector(selectGlobal, (globalState) =>
  globalState.get("tokenVerify")
  );

const makeSelectAuth = () =>
  createSelector(selectGlobal, (globalState) => globalState.get("auth").toJS());

export {
  selectGlobal,
  makeSelectLocation,
  makeSelectLoading,
  makeSelectLoadingMessage,
  makeSelectAuth,
  makeSelectToken,
  makeSelectAppUpdate,
};
