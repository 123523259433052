import { call } from "redux-saga/effects";
import request from "utils/request";

export function* dispatchRequest(endpoint, requestOpt, hasResponse = true) {
  try {
    if (hasResponse) {
      const response = yield call(request, endpoint, requestOpt);
      return response;
    }
    yield call(request, endpoint, requestOpt);
    return true;
  } catch (err) {
    throw err;
  }
}

export function* recurse(handler) {
  try {
    return yield call(handler);
  } catch (err) {
    if (err.message === "TOKEN_REFRESHED") {
      return yield call(recurse, handler);
    }
    throw err;
  }
}
