import {
  DEFAULT_ACTION,
  SET_APP_LOADING_ACTION,
  SET_AUTH_TOKEN_ACTION,
  SET_VERIFY_TOKEN_ACTION,
  GET_VERIFY_TOKEN_ACTION,
  SET_APP_UPDATE_ACTION,
} from "./constants";

export function defaultAction() {
  return {
    type: DEFAULT_ACTION,
  };
}

export function setVerifyTokenAction(payload) {
  return {
    type: SET_VERIFY_TOKEN_ACTION,
    payload,
  };
}
export function getVerifyTokenAction(payload) {
  return {
    type: GET_VERIFY_TOKEN_ACTION,
    payload,
  };
}

export function setAuthTokenAction(token) {
  return {
    type: SET_AUTH_TOKEN_ACTION,
    payload: token,
  };
}

export function setAppUpdateAction(boolean) {
  return {
    type: SET_APP_UPDATE_ACTION,
    payload: boolean,
  };
}

export function setAppLoadingAction(loading, message = {}) {
  return {
    type: SET_APP_LOADING_ACTION,
    payload: {
      loading,
      message,
    },
  };
}
