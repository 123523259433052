export default {
  api: {
    apiHost: process.env.API_HOST,
    apiHostQe: process.env.API_HOST_QE,
  },
  config: {
    apiSubKey: process.env.API_SUB_KEY,
    apiSubKeyQe: process.env.API_SUB_KEY_QE,
    tenantId: process.env.TENANT_ID,
  },
};
