import React from 'react';
import PropTypes from 'prop-types';
import { Box, Hidden } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import Typography from 'components/Typography';
import styled from 'styled-components';

const OggFontText = styled(Typography)`
  && {
    padding: ${props => props.theme.spacing(0, 2)};
    font-family: 'Ogg';
    font-style: italic;
    @media (max-width: 768px) {
      padding: ${props => props.theme.spacing(0, 1)};
    }
  }
`;
function HeaderTitle(props) {
  const { text1, text2, text3 } = props;
  const theme = useTheme();
  const renderTitle1 = text => (
    <Typography variant="h3" weight={300} align="center">
      {text}
    </Typography>
  );

  const renderTitle2 = text => (
    <OggFontText
      theme={theme}
      variant="h3"
      weight={500}
      style={{
        fontFamily: 'Ogg',
        fontStyle: 'italic',
      }}>
      {text}
    </OggFontText>
  );

  const renderTitle3 = text => (
    <Typography variant="h3" weight={300} align="center">
      {text}
    </Typography>
  );
  return (
    <>
      <Hidden smDown>
        <Box display="flex" alignItems="center" justifyContent="center" pb={3}>
          {renderTitle1(text1)}
          {renderTitle2(text2)}
          {renderTitle3(text3)}
        </Box>
      </Hidden>
      <Hidden mdUp>
        <Box display="flex" alignItems="center" justifyContent="center">
          {renderTitle1(text1)}
          {renderTitle2(text2)}
        </Box>
        {renderTitle3(text3)}
      </Hidden>
    </>
  );
}

HeaderTitle.propTypes = {
  text1: PropTypes.string,
  text2: PropTypes.string,
  text3: PropTypes.string,
};

export default HeaderTitle;
