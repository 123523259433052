import { Grid } from '@material-ui/core';
import styled from 'styled-components';
import { color } from 'styles/color';

const TextWrapper = styled(Grid)`
  && {
    color: ${color.primary};
    font-size: 2.5rem;
    margin-bottom: ${props => props.theme.spacing(3)}px;
    font-weight: 300;
    @media (max-width: 768px) {
      font-size: ${props => props.theme.spacing(3)}px;
      margin-bottom: ${props => props.theme.spacing(1.5)}px;
    }
  }
`;

export default TextWrapper;
