import React, { useState, useRef } from "react";
import Popover, { PopoverAnimationVertical } from "@material-ui/core/Popover";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import WarningIcon from "@material-ui/icons/Warning";
import { Box, Paper, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: "none",
    // zIndex: 1,
  },
  paper: {
    backgroundColor: "rgba(0, 0, 0, 0.0)",
    marginTop: theme.spacing(0.6),
    boxShadow: "none",
  },
  arrow: {
    backgroundColor: "rgba(0, 0, 0, 0.0)",
    position: "absolute",
    left: "45%",
    fontSize: 7,
    width: "5em",
    height: "5em",
    "&::before": {
      content: '""',
      margin: "2px auto",
      display: "block",
      width: 4,
      height: 4,
      border: "solid 6px #333333",
      transform: "rotate(45deg)",
    },
  },
  paperContent: {
    padding: theme.spacing(1),
    backgroundColor: "#333333",
    marginTop: theme.spacing(0.6),
  },
}));

export default function CustomPopover({ errorMessage, id, ml = 0, mb = 3 }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [arrowRef, setArrowRef] = useState(null);
  const [arrow, setArrow] = useState(false);

  const classes = useStyles();
  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
    setArrow(true);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setArrow(false);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <WarningIcon
        // component="span"
        aria-owns={open ? id : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        style={{
          fontSize: 15,
          marginBottom: mb,
          marginLeft: ml,
          fill: "#BE5D5D",
        }}
      />

      <Popover
        id={id}
        className={classes.popover}
        classes={{
          // popover: classes.popover,
          paper: classes.paper,
          // arrow: classes.arrow,
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
        style={{ top: 10 }}
        animation={PopoverAnimationVertical}
        modifiers={{
          arrow: {
            enabled: true,
            element: arrowRef,
          },
        }}
      >
        {arrow && <Box className={classes.arrow} ref={setArrowRef} />}
        <Paper className={classes.paperContent}>
          <Typography style={{ color: "#FBBC0E", fontSize: '14px', fontWeight: 'bold' }} variant="body1">
            Error
          </Typography>
          <Typography style={{ color: "white" }} variant="caption">
            {errorMessage}
          </Typography>
        </Paper>
      </Popover>
    </>
  );
}
