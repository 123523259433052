export const color = {
  black: '#000000',
  white: '#ffffff',
  primary: '#23366F',
  secondary: '#2167AE',
  textSecondary: '#A6ADAF',
  error: '#FF7569',
  indicator: '#1FB1E6',
  lightGreyBg: '#EEF0F1',
  snackBarUpdate: '#0288D1',
  grey: '#969696',
  retirementBg: '#F8B9AA',
  loading: '#726F6F',
};