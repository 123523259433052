/*
 * RetirementPage Messages
 *
 * This contains all the text for the RetirementPageV2 container.
 */

import { defineMessages } from "react-intl";

export const scope = "app.containers.RiskToolPage";

export default defineMessages({
  header: {
    id: `${scope}.header`,
    defaultMessage: "This is the RiskToolPage container!",
  },
  loading: {
    id: `${scope}.loading`,
    defaultMessage: "Loading...",
  },
  title: {
    id: `${scope}.title`,
    defaultMessage: "Retirement Planning",
  },
  login: {
    id: "${scope}.login",
    defaultMessage: "Log in to ZIO to view Fund Centre"
  },
  quotation: {
    id: `${scope}.quotation`,
    defaultMessage: "How much do I need to save ?",
  },
  assumedNote: {
    id: "${scope}.assumedNote",
    defaultMessage: "Assumed values are based on the retirement country selected but you can input your own values if required"
  },
  assume: {
    id: `${scope}.assume`,
    defaultMessage:
      "Assumed values are based on the retirement country selected but you can { br } input your own values if required",
  },
  riskQuestionnaire: {
    id: `${scope}.riskQuestionnaire`,
    defaultMessage: "Start Risk Questionnaire",
  },
  riskQuestionTitile: {
    id: `${scope}.riskQuestionTitile`,
    defaultMessage: "Risk questionnaire",
  },
  next: {
    id: `${scope}.next`,
    defaultMessage: "Next",
  },
  back: {
    id: `${scope}.back`,
    defaultMessage: "Back",
  },
  close: {
    id: `${scope}.close`,
    defaultMessage: "Close",
  },
  downReport: {
    id: `${scope}.downReport`,
    defaultMessage: "Download Report",
  },
  retakeQuestion: {
    id: `${scope}.retakeQuestion`,
    defaultMessage: "Retake questionnaire",
  },
  question1: {
    id: `${scope}.question1`,
    defaultMessage: "What is your expected annual return?",
  },
  question2: {
    id: `${scope}.question2`,
    defaultMessage: "What is your minimum expected annual return?",
  },
  question3: {
    id: `${scope}.question3`,
    defaultMessage:
      "Assume that your portfolio has 5 years of good performance, then declines by {percent}% in a single year.{br} What percentage of your investment would you sell?",
  },
  question4: {
    id: `${scope}.question4`,
    defaultMessage:
      "Which portfolio would you select? {br} Portfolio A has a 50/50 chance of gaining either {DynamicQues1}% or {DynamicQues2 }% whereas Portfolio B, has a guaranteed gain of {DynamicQues3}% ",
  },
  question5: {
    id: `${scope}.question5`,
    defaultMessage:
      "Which portfolio would you select? {br} Portfolio A has a 50/50 chance of either gaining {DynamicQues4}% or losing {DynamicQues5}%. Whereas Portfolio B, you are sure of making no profit or loss?",
  },
  question6: {
    id: `${scope}.question6`,
    defaultMessage:
      "Your answers suggest your risk appetite is high. This means that you aim for high possible returns and accept higher level of risk, recognising the value of your investment may fluctuate very sharply, particularly over the short-term. Funds with Aggressive Risk Profile are likely to invest high risk assets, such as equities or commodities. The assets may see large day-to-day changes in value, both negative and positive.",
  },
  summaryTitle: {
    id: `${scope}.summaryTitle`,
    defaultMessage: "Our assessment of your risk profile",
  },
  summary: {
    id: `${scope}.summary1`,
    defaultMessage:
      "Your answers suggest that your risk appetite is { value }. ",
  },
  simulate: {
    id: `${scope}.simulate`,
    defaultMessage: "Simulate net wealth",
  },
  lowRiskProfileAssume: {
    id: `${scope}.lowRiskProfileAssume`,
    defaultMessage:
      "This means that you will accept limited risks with your money but will want to try to avoid large fluctuations in the value of your investment, accepting the potential of more modest returns to achieve this.",
  },
  lowRiskProfileSuggest: {
    id: `${scope}.lowRiskProfile`,
    defaultMessage:
      "Funds with this Risk Profile may include a high proportion of bank deposits, fixed interest investments, such as government bonds and high-quality corporate bonds (bonds issued by companies) and may include a small proportion invested in equities (company shares). Over the long term, high returns are unlikely, and the returns may not keep pace with inflation.",
  },
  mediumLowRiskProfileAssume: {
    id: `${scope}.mediumLowRiskProfileAssume`,
    defaultMessage:
      "This means that you are likely to accept some risk in return for the potential of higher investment gains over the longer term. You will want to try to avoid large fluctuations in the value of your investment, but accept there will be some fluctuation, particularly over the shorter term",
  },
  mediumLowRiskProfileSuggest: {
    id: `${scope}.mediumLowRiskProfile`,
    defaultMessage:
      "Funds with this Risk Profile are likely to be made up mainly of fixed interest investments such as government bonds and high-quality corporate bonds (bonds issued by companies) and bank deposits. These funds are also likely to contain a significant proportion invested in equities (company shares).",
  },
  mediumRiskProfileAssume: {
    id: `${scope}.mediumRiskProfileAssume`,
    defaultMessage:
      "This means that you are likely to accept significant risk in return for the potential for good investment gains over the longer term. You accept there will be significant fluctuations in the value of your investment, particularly over the shorter term. However, you will want to limit the amount of your money held in more risky investments.",
  },
  mediumRiskProfileSuggest: {
    id: `${scope}.mediumRiskProfile`,
    defaultMessage:
      "Funds with this Risk Profile may invest in a mix of equities (company shares), and fixed interest investments such as government bonds and corporate bonds (bonds issued by companies).",
  },
  mediumHighRiskProfileAssume: {
    id: `${scope}.mediumHighRiskProfileAssume`,
    defaultMessage:
      "This means that you understand that the value of your investment can go down and up sharply with the potential for greater returns over the longer term",
  },
  mediumHighRiskProfileSuggest: {
    id: `${scope}.mediumHighRiskProfile`,
    defaultMessage:
      "Funds with this Risk Profile may include a high proportion of equities (company shares), and a smaller proportion of fixed interest investments such as government bonds and corporate bonds (bonds issued by companies).",
  },
  highRiskProfileAssume: {
    id: `${scope}.highRiskProfileAssume`,
    defaultMessage:
      "This means that you aim for high possible returns and accept higher levels of risk, recognising the value of your investment may fluctuate very sharply, particularly over the short-term.",
  },
  highRiskProfileSuggest: {
    id: `${scope}.highRiskProfile`,
    defaultMessage:
      "Funds with this Risk Profile are likely to invest in high-risk assets, such as equities or commodities. The assets may see large day-to-day changes in value, both negative and positive.",
  },
  result: {
    id: `${scope}.result`,
    defaultMessage: "Risk assessment",
  },
  resultDescription: {
    id: `${scope}.resultDescription`,
    defaultMessage:
      "Target fund for retirement income  {br} to age 83 (average life expectancy)",
  },
  resultChart1: {
    id: `${scope}.resultChart1`,
    defaultMessage: "What you may need",
  },
  mbResultChart1: {
    id: `${scope}.mbResultChart1`,
    defaultMessage: "You may need",
  },
  resultChart2: {
    id: `${scope}.resultChart2`,
    defaultMessage: "What you may have",
  },
  mbResultChart2: {
    id: `${scope}.mbResultChart2`,
    defaultMessage: "You may have",
  },
  resultChart3: {
    id: `${scope}.resultChart3`,
    defaultMessage: "Your shortfall",
  },
  simulatorCase2: {
    id: `${scope}.simulatorCase2`,
    defaultMessage:
      "You have a significant retirement gap of {currency} {amount} and it is highly likely that you'll run out of money by {year}. ",
  },
  error1: {
    id: `${scope}.error1`,
    defaultMessage:
      "Please choose age between 18 and 76 from the dropdown list.",
  },
  error2: {
    id: `${scope}.error2`,
    defaultMessage: "Please choose Country from the dropdown list.",
  },
  error3: {
    id: `${scope}.error3`,
    defaultMessage:
      "Please enter retirement age between { currentAge } and 83.",
  },
  error4: {
    id: `${scope}.error4`,
    defaultMessage:
      "Please enter monthly income between 500 and 9,999,999,999.",
  },
  error5: {
    id: `${scope}.error5`,
    defaultMessage:
      "Please enter monthly expense between 0 and {monthlyIncome}. ",
  },
  error6: {
    id: `${scope}.error6`,
    defaultMessage:
      "Please enter monthly retirement savings between 0 and { value }.",
  },
  error7: {
    id: `${scope}.error7`,
    defaultMessage:
      "Please enter current savings for retirement savings between 0 and 9,999,999,999,999.",
  },
  error8: {
    id: `${scope}.error8`,
    defaultMessage:
      "Please enter Rate of Inflation between {min}% and {max}%. ",
  },
  error9: {
    id: `${scope}.error9`,
    defaultMessage:
      "Please enter Rate of Return from Saving between {min}% and {max}%. ",
  },
  error10: {
    id: `${scope}.error10`,
    defaultMessage: "Please choose Currency from the dropdown list.",
  },
  error11: {
    id: `${scope}.error11`,
    defaultMessage: "Please choose Lifestyle from the dropdown list.",
  },
  riskProfile: {
    id: `${scope}.riskProfile`,
    defaultMessage: "Risk Profile",
  },
  simulateTitile: {
    id: `${scope}.simulateTitile`,
    defaultMessage: "Your Projected Net Wealth",
  },
  changeOrientation: {
    id: `${scope}.changeOrientation`,
    defaultMessage:
      "The Scenario Simulator is best viewed when your screen is horizontal. Please rotate your screen",
  },
  agree: {
    id: `${scope}.agree`,
    defaultMessage: "Got it, Let''s go",
  },
  simulatorCase1: {
    id: `${scope}.simulatorCase1`,
    defaultMessage:
      "Great news! You have no shortfall and we project that you will achieve your retirement goal.",
  },
  simulatorCase2: {
    id: `${scope}.simulatorCase2`,
    defaultMessage:
      "You have a significant retirement gap of {currency} {amount} and it is highly likely that you'll run out of money by {year}. ",
  },
  netWealthTitle: {
    id: `${scope}.netWealthTitle`,
    defaultMessage: "Your projected net wealth",
  },
  netWealth: {
    id: `${scope}.netWealth`,
    defaultMessage: "Net wealth",
  },
  addStress: {
    id: `${scope}.addStress`,
    defaultMessage: "Add Life Event",
  },
  noteShortFall: {
    id: `${scope}.noteShortFall`,
    defaultMessage:
      " the Shortfall in the Scenario Visualizer graph is different from the Shortfall in the Retirement Planner.",
  },
  noteShortFall2: {
    id: `${scope}.noteShortFall2`,
    defaultMessage:
      "The Shortfall in the Scenario Visualizer graph is the difference between your retirement needs and your stochastically calculated wealth over years, whereas the shortfall in the Retirement Planner is the difference between your retirement needs and your saving for retirement over the years.",
  },
  shortFall: {
    id: `${scope}.shortFall`,
    defaultMessage: "Retirement needs",
  },
  generatingReport: {
    id: `${scope}.generatingReport`,
    defaultMessage: "Generating report...",
  },
  select: {
    id: `${scope}.select`,
    defaultMessage: "Select your needs",
  },
  reminder: {
    id: `${scope}.reminder`,
    defaultMessage: "You can only choose one scenario at a time.",
  },
  note: {
    id: `${scope}.note`,
    defaultMessage: "Please Note:",
  },
  outOfMoney: {
    id: `${scope}.outOfMoney`,
    defaultMessage: "{year}{br} Out of Money",
  },
  retirement: {
    id: `${scope}.retirement`,
    defaultMessage: "{year}{br} Planned retirement",
  },
  landingTitle: {
    id: `${scope}.landingTitle`,
    defaultMessage: "Attitude to Risk Questionnaire",
  },
  getStarted: {
    id: `${scope}.getStarted`,
    defaultMessage: "Get Started",
  },
  estimate: {
    id: `${scope}.estimate`,
    defaultMessage: "Estimated Completion time: 5 minutes",
  },
  defensive: {
    id: `${scope}.defensive`,
    defaultMessage: "Defensive",
  },
  adventurous: {
    id: `${scope}.adventurous`,
    defaultMessage: "Adventurous",
  },
  download: {
    id: `${scope}.download`,
    defaultMessage: "Download Report",
  },
  fiftyPercent: {
    id: `${scope}.fiftyPercent`,
    defaultMessage: "50%",
  },
  oneHundredPercent: {
    id: `${scope}.oneHundredPercent`,
    defaultMessage: "100%",
  },
  returnPercent: {
    id: `${scope}.returnPercent`,
    defaultMessage: "probability of {percent}% return",
  },
  lossPercent: {
    id: `${scope}.lossPercent`,
    defaultMessage: "probability of {percent}% loss",
  },
  portfolioA: {
    id: `${scope}.portfolioA`,
    defaultMessage: "portfolio A",
  },
  portfolioB: {
    id: `${scope}.portfolioB`,
    defaultMessage: "portfolio B",
  },
});
