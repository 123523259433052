import React, { forwardRef, useState } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import _ from "lodash";
import useStyles from "../styles";
import { useForm } from "react-hook-form";
import messages from "../../messages";
import ChartIcon from "images/ic_chart.svg";
import GrowthIcon from "images/ic_growth.svg";
import DistanceIcon from "images/ic_distance.svg";
import CoinIcon from "images/ic_coin.svg";
import Summary from "../Summary";
import ChartQuestion from "../ChartQuestion";
import DropdownQuestion from "../DropdownQuestion";
import {
  RISK_OPTIONS,
  SELLING_RATE_OPTIONS,
} from "containers/RiskToolPage/constants";
import { Container } from "@material-ui/core";

const Questionnaire = forwardRef((props, ref) => {
  const classes = useStyles();
  const {
    retirementData,
    initialValues,
    setRiskAction,
    getRiskToolAction,
    riskToolData,
    handleRetake,
    handleLoginZIO,
    isLoading,
    step,
    setStep,
    backStep,
    riskData,
    isMobileSize,
    error,
  } = props;

  const [portfolioQ4, setPortfolioQ4] = useState(
    riskData?.AttitudeUncertainty ? riskData?.AttitudeUncertainty : "0"
  );
  const [portfolioQ5, setPortfolioQ5] = useState(
    riskData?.AttitudeLosses ? riskData?.AttitudeLosses : "0"
  );

  const [dropdown, setDropdown] = useState({
    expectedAnnualReturn: initialValues?.expectedAnnualReturn
      ? initialValues?.expectedAnnualReturn
      : "select",
    minimumAnnualReturn: initialValues?.minimumAnnualReturn
      ? initialValues?.minimumAnnualReturn
      : "select",
    percentageOfSellingRate: initialValues?.percentageOfSellingRate
      ? initialValues?.percentageOfSellingRate
      : "select",
  });

  const {
    handleSubmit,
    register,
    watch,
    getValues,
    formState: { errors, isDirty },
  } = useForm({
    defaultValues: initialValues,
  });

  const getPortfolioOptions = (value) => {
    switch (value) {
      case 0.09:
        return 5;
      case 0.07:
        return 4;
      case 0.05:
        return 3;
      case 0.03:
        return 2;
      case 0.01:
        return 1;
    }
  };
  const getRiskOptionsAnswer = getPortfolioOptions(
    getValues("expectedAnnualReturn")
  );
  const portfolioOptions = Array.from(
    { length: getRiskOptionsAnswer },
    (_, k) => ({
      key: `p${k + 1}`,
      value: (k + 1) / 100 + k * 0.01,
      label: `${k + 1 + k}%`,
    })
  );

  const watchAllFields = watch();

  const onSubmit = () => {
    getRiskToolAction(attituteToRiskPayload);
  };

  // Handle for dynamic percentage on question from 1 to 5
  const CONST = 2 / 100;
  const CONST2 = 1 / 100;
  let EXPECTED_RETURN = _.get(watchAllFields, ["expectedAnnualReturn"]);
  let DISAPOINT_RETURN = _.get(watchAllFields, ["minimumAnnualReturn"]);
  let REVISED_RETURN;

  if (EXPECTED_RETURN - DISAPOINT_RETURN < CONST) {
    REVISED_RETURN = CONST + DISAPOINT_RETURN;
  } else {
    REVISED_RETURN = EXPECTED_RETURN;
  }

  let questTitle1 = Math.round((REVISED_RETURN * 2 - DISAPOINT_RETURN) * 100);
  let questTitle2 = Math.round(DISAPOINT_RETURN * 100);
  let questTitle3 = Math.round((REVISED_RETURN - CONST2) * 100);
  let questTitle4 = Math.round(REVISED_RETURN * 100);
  let questTitle5 = Math.round((REVISED_RETURN - CONST2) * 100);
  let CELL_L_THIRTYTREE = REVISED_RETURN - 0.01;
  let ANS_LOSS = CELL_L_THIRTYTREE;
  let ATT_LOSS = Math.min((EXPECTED_RETURN * -1) / ANS_LOSS, 2.5);
  let questTitle3Percent = (((200 * REVISED_RETURN) / ATT_LOSS) * -1).toFixed(
    0
  );
  // end of the function

  const data = {
    datas1: [
      { label: "", value: questTitle1 },
      { label: "", value: questTitle2 },
    ],
    datas2: [
      { label: "", value: questTitle3 },
      { label: "", value: 0 },
    ],
    datas3: [
      { label: "", value: questTitle4 },
      { label: "", value: questTitle5 * -1 },
    ],
    datas4: [
      { label: "", value: 0 },
      { label: "", value: 0 },
    ],
  };

  const nextStep = () => {
    if (step < 6) {
      setStep(step + 1);
    }
    if (step === 5) {
      onSubmit();
    }
    setRiskAction(attituteToRiskPayload);
  };

  const checkProgress = (step, nextStep, backStep) => {
    let title = "";
    if (step <= 6) {
      title = messages[`question${step}`].defaultMessage;
    }
    switch (step) {
      case 1:
        return (
          <DropdownQuestion
            classes={classes}
            errors={errors}
            register={register}
            step={step}
            handleNextStep={nextStep}
            title={title}
            image={GrowthIcon}
            name="expectedAnnualReturn"
            options={RISK_OPTIONS}
            watch={watchAllFields}
            getValues={getValues}
            isDirty={isDirty}
            riskData={riskData}
            backStep={backStep}
            dropdown={dropdown}
            setDropdown={setDropdown}
          />
        );
      case 2:
        return (
          <DropdownQuestion
            classes={classes}
            errors={errors}
            register={register}
            step={step}
            handleNextStep={nextStep}
            title={title}
            image={ChartIcon}
            name="minimumAnnualReturn"
            options={portfolioOptions}
            watch={watchAllFields}
            getValues={getValues}
            isDirty={isDirty}
            riskData={riskData}
            backStep={backStep}
            dropdown={dropdown}
            setDropdown={setDropdown}
          />
        );
      case 3:
        return (
          <DropdownQuestion
            classes={classes}
            errors={errors}
            register={register}
            step={step}
            handleNextStep={nextStep}
            title={
              <FormattedMessage
                {...messages[`question${step}`]}
                values={{
                  percent: questTitle3Percent,
                  br: isMobileSize ? "" : <br />,
                }}
              />
            }
            image={CoinIcon}
            name="percentageOfSellingRate"
            options={SELLING_RATE_OPTIONS}
            watch={watchAllFields}
            getValues={getValues}
            isDirty={isDirty}
            riskData={riskData}
            backStep={backStep}
            dropdown={dropdown}
            setDropdown={setDropdown}
          />
        );
      case 4:
        return (
          <ChartQuestion
            defaultValue={portfolioQ4}
            setValue={setPortfolioQ4}
            step={step}
            handleNextStep={nextStep}
            title={
              <FormattedMessage
                {...messages[`question${step}`]}
                values={{
                  DynamicQues1: questTitle1,
                  DynamicQues2: questTitle2,
                  DynamicQues3: questTitle3,
                  br: <br />,
                }}
              />
            }
            image={DistanceIcon}
            name="portfolio1"
            datas1={data.datas1}
            datas2={data.datas2}
            backStep={backStep}
          />
        );
      case 5:
        return (
          <ChartQuestion
            defaultValue={portfolioQ5}
            setValue={setPortfolioQ5}
            step={step}
            handleNextStep={nextStep}
            title={
              <FormattedMessage
                {...messages[`question${step}`]}
                values={{
                  DynamicQues4: questTitle4,
                  DynamicQues5: questTitle5,
                  br: <br />,
                }}
              />
            }
            image={DistanceIcon}
            name="portfolio2"
            datas1={data.datas3}
            datas2={data.datas4}
            backStep={backStep}
          />
        );
      case 6:
        return (
          <Summary
            data={riskToolData}
            handleNextStep={nextStep}
            handleRetake={handleRetake}
            handleLoginZIO={handleLoginZIO}
            isLoading={isLoading}
            backStep={backStep}
            error={error}
          />
        );
      default:
        return "";
    }
  };

  let attituteToRiskPayload = {
    CurrentSavings: retirementData?.currentSavings * 1,
    SavingsPerMonth: retirementData?.plannedSavings * 1,
    ReturnRate: retirementData?.growthRate * 1,
    CurrentAge: retirementData?.currentAge,
    RetirementAge: retirementData?.retirementAge,
    ExpectedReturns: _.get(watchAllFields, ["expectedAnnualReturn"]),
    MinExpectedReturns: _.get(watchAllFields, ["minimumAnnualReturn"]),
    InvestTemperament: _.get(watchAllFields, ["percentageOfSellingRate"]),
    AttitudeUncertainty: portfolioQ4,
    AttitudeLosses: portfolioQ5,
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Container maxWidth="lg">
        {checkProgress(step, nextStep, backStep)}
      </Container>
    </form>
  );
});

Questionnaire.propTypes = {
  handleSubmitForm: PropTypes.func,
  retirementData: PropTypes.object,
  getRiskToolAction: PropTypes.func,
  riskToolData: PropTypes.object,
  handleShowResult: PropTypes.func,
  isLoading: PropTypes.bool,
  isMobileSize: PropTypes.bool,
};

export default Questionnaire;
