import React, { useEffect } from "react";
import { Grid, Typography, Button, Box } from "@material-ui/core";
import { PropTypes } from "prop-types";
import ATTITUTE_BANNER from "images/img_ART_landing.png";
import messages from "../../messages";
import { FormattedMessage } from "react-intl";
import VECTOR_LANDING from "images/vector_landing.svg";
import useMobileStyles from "../mobileStyles";

export default function MobileLanding({ onStart }) {
  const classes = useMobileStyles();

  const handleGetStarted = () => {
    onStart();
  };

  return (
    <Grid container direction="column" style={{ overflow: "hidden" }}>
      <Grid item>
        <img
          src={ATTITUTE_BANNER}
          alt="ATR banner"
          width="200%"
          style={{ marginLeft: -210 }}
        />
      </Grid>
      <Grid item container>
        <Box mt={-22}>
          <img src={VECTOR_LANDING} alt="vector landing" />
        </Box>
      </Grid>
      <Grid
        item
        container
        direction="column"
        style={{
          height: 200,
          borderRadius: "0 38px 0 0",
          marginTop: -120,
          backgroundColor: "#FFF",
        }}
        justifyContent="center"
        alignItems="center"
        spacing={2}
      >
        <Grid item>
          <Typography
            variant="h4"
            color="primary"
            style={{ fontSize: 23, fontWeight: "bold" }}
          >
            <FormattedMessage {...messages?.landingTitle} />
          </Typography>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            style={{
              height: 41,
              borderRadius: 30,
              textTransform: "none",
              width: 235,
            }}
            onClick={handleGetStarted}
          >
            <Typography style={{ fontSize: 14, fontWeight: "bold" }}>
              <FormattedMessage {...messages.getStarted} />
            </Typography>
          </Button>
        </Grid>
        <Grid item>
          <Typography
            variant="body1"
            className={classes.customTextSize}
            style={{ color: "#969696" }}
          >
            <FormattedMessage {...messages.estimate} />
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}
MobileLanding.propTypes = {
  onStart: PropTypes.func,
};
