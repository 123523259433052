/*
 *
 * RiskTool reducer
 *
 */

import { fromJS } from "immutable";
import {
  DEFAULT_ACTION,
  SET_RESOURCE_ACTION,
  SET_OPTIONAL_RESOURCE_ACTION,
  SET_RETIREMENT_ACTION,
  SET_RETIREMENT_TOOL_ACTION,
  SET_RISK_TOOL_ACTION,
  SET_LOADING_ACTION,
  SET_ERROR_ACTION,
  SET_STEP_ACTION,
  SET_RISK_ACTION,
} from "./constants";

const defaultState = {
  resources: [],
  optionalResources: [],
  dataRetirement: {},
  riskToolData: {},
  retirementToolData: {},
  isLoading: false,
  error: {
    isError: false,
    status: null,
  },
  step: 0,
  dataRisk: {},
};
export const initialState = fromJS({ ...defaultState });

function riskToolPageReducer(state = initialState, action) {
  switch (action.type) {
    case DEFAULT_ACTION:
      return state;
    case SET_RESOURCE_ACTION:
      return state.set("resources", action.payload);
    case SET_OPTIONAL_RESOURCE_ACTION:
      return state.set("optionalResources", action.payload);
    case SET_RETIREMENT_ACTION:
      return state.set("dataRetirement", action.payload);
    case SET_RISK_TOOL_ACTION:
      return state.set("riskToolData", action.payload);
    case SET_RISK_ACTION:
      return state.set("dataRisk", action.payload);
    case SET_RETIREMENT_TOOL_ACTION:
      return state.set("retirementToolData", action.payload);
    case SET_LOADING_ACTION:
      return state.set("isLoading", action.payload);
    case SET_ERROR_ACTION:
      return state.set("error", fromJS(action.payload));
    case SET_STEP_ACTION:
      return state.set("step", action.payload);
    default:
      return state;
  }
}

export default riskToolPageReducer;
