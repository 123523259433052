import { createSelector } from "reselect";
import { initialState, defaultState } from "./reducer";

/**
 * Direct selector to the retirementPage state domain
 */

const selectRiskToolPageDomain = (state) =>
  state.get("riskToolPage", initialState);

/**
 * Other specific selectors
 */
const makeGetResources = () =>
  createSelector(selectRiskToolPageDomain, (substate) =>
    substate.get("resources")
  );

const makeGetOptionalResources = () =>
  createSelector(selectRiskToolPageDomain, (substate) =>
    substate.get("optionalResources")
  );

const makeSelectRetirement = () =>
  createSelector(selectRiskToolPageDomain, (substate) =>
    substate.get("dataRetirement")
  );

const makeSelectRetirementTool = () =>
  createSelector(selectRiskToolPageDomain, (substate) =>
    substate.get("retirementToolData")
  );

const makeSelectRiskTool = () =>
  createSelector(selectRiskToolPageDomain, (substate) =>
    substate.get("riskToolData")
  );

const makeSelectLoading = () =>
  createSelector(selectRiskToolPageDomain, (substate) =>
    substate.get("isLoading")
  );

const makeSelectError = () =>
  createSelector(selectRiskToolPageDomain, (substate) =>
    substate.get("error").toJS()
  );

  const makeSelectStep = () =>
  createSelector(selectRiskToolPageDomain, (substate) =>
    substate.get("step")
  );

  const makeSelectRisk = () =>
  createSelector(selectRiskToolPageDomain, (substate) =>
    substate.get("dataRisk")
  );

/**
 * Default selector used by RetirementPage
 */

const makeSelectRetirementPage = () =>
  createSelector(selectRiskToolPageDomain, (substate) => substate.toJS());

export default makeSelectRetirementPage;
export {
  selectRiskToolPageDomain,
  makeGetResources,
  makeGetOptionalResources,
  makeSelectRetirement,
  makeSelectRetirementTool,
  makeSelectRiskTool,
  makeSelectLoading,
  makeSelectError,
  makeSelectStep,
  makeSelectRisk
};
