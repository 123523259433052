import { Grid } from '@material-ui/core';
import styled from 'styled-components';

const BodyWrapper = styled(Grid)`
  && {
    display: flex;
    justify-content: space-between;
    @media (max-width: 768px) {
      flex-direction: column;
    }
  }
`;

export default BodyWrapper;
