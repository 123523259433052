import messages from "../containers/RiskToolPage/messages";

export const checkType = (value) => {
  switch (value) {
    case 1:
      return "Defensive";
    case 2:
      return "Cautious";
    case 3:
      return "Balanced";
    case 4:
      return "Growth";
    case 5:
      return "Adventurous";
  }
};

export const checkRiskProfile = (value) => {
  switch (value) {
    case 1:
      return "Low";
    case 2:
      return "Medium-low";
    case 3:
      return "Medium";
    case 4:
      return "Medium-high";
    case 5:
      return "High";
  }
};

export const checkAssumeMessages = (value) => {
  switch (value) {
    case 1:
      return messages.lowRiskProfileAssume;
    case 2:
      return messages.mediumLowRiskProfileAssume;
    case 3:
      return messages.mediumRiskProfileAssume;
    case 4:
      return messages.mediumHighRiskProfileAssume;
    case 5:
      return messages.highRiskProfileAssume;
  }
};

export const checkSuggestMessages = (value) => {
  switch (value) {
    case 1:
      return messages.lowRiskProfileSuggest;
    case 2:
      return messages.mediumLowRiskProfileSuggest;
    case 3:
      return messages.mediumRiskProfileSuggest;
    case 4:
      return messages.mediumHighRiskProfileSuggest;
    case 5:
      return messages.highRiskProfileSuggest;
  }
};
