import React, { useContext } from "react";
import { Grid, Typography, Button } from "@material-ui/core";
import BarChart from "components/BarChart";
import { PropTypes } from "prop-types";
import useStyles from "../styles";
import useMobileStyles from "../mobileStyles";
import { useTheme } from "@material-ui/core/styles";
import { FormattedMessage } from "react-intl";
import messages from "../../messages";
import styled from "styled-components";
import DeviceContext from "components/DetectDevice";
import { GridWrapper } from "components";
import { useEffect } from "react";

const portfolioAColor = ["#2167AE", "#23366F"];
const portfolioBColor = ["#5495CF", "#91BFE3"];
const TypographyCustom = styled(Typography)`
  && {
    @media only screen and (min-width: 1800px) {
      font-size: 3rem;
    }
    @media (max-width: 1400px) {
      font-size: 3rem;
    }
  }
`;
export default function ChartQuestion({
  defaultValue,
  setValue,
  step,
  handleNextStep,
  title,
  image,
  name,
  datas1,
  datas2,
  backStep,
}) {
  const PA = "Portfolio A";
  const PB = "Portfolio B";
  const theme = useTheme();
  let isMobileSize = useContext(DeviceContext);
  const classes = isMobileSize ? useMobileStyles() : useStyles();
  const handleChoose = (value) => {
    setValue(value);
  };
  const isDisabled = defaultValue === "0" ? true : false;

  const arr = [
    ...Object.values(datas1).map((data) => data.value),
    ...Object.values(datas2).map((data) => data.value),
  ];

  const rawBiggestValue = Math.max.apply(null, arr);

  const biggestValue =
    rawBiggestValue % 2 === 0 ? rawBiggestValue + 2 : rawBiggestValue + 1;

  const rawSmallestValue = Math.min.apply(null, arr);

  const smallestValue =
    rawSmallestValue !== 0
      ? rawSmallestValue % 2 === 0
        ? rawSmallestValue - 2
        : rawSmallestValue - 1
      : 0;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [step]);

  return (
    <>
      {isMobileSize ? (
        <GridWrapper
          item
          container
          justifyContent="space-between"
          alignItems="center"
          direction="row"
          style={{ height: `${window.innerHeight}px` }}
        >
          <Grid item container direction="column" xs={12}>
            <Grid item container justifyContent="center">
              <Typography
                variant="h2"
                color="primary"
                className={classes.titleText}
              >
                <FormattedMessage {...messages?.riskQuestionTitile} />
              </Typography>
              <Grid
                item
                container
                xs={12}
                style={{ paddingTop: 18, textAlign: "justify" }}
              >
                <Typography
                  variant="body1"
                  color="secondary"
                  style={{ fontSize: 15 }}
                  gutterBottom
                >
                  {`0${step}.`} {title}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            item
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            xs={12}
          >
            <Grid
              item
              className={classes.chartContainer}
              style={{
                border: defaultValue === "Portfolio A" && ` 1px solid #929292`,
                boxShadow: defaultValue === "Portfolio A" && theme.shadows[1],
                borderRadius: isMobileSize ? 10 : 0,
              }}
              onClick={() => handleChoose(PA)}
              spacing={2}
            >
              <Grid item className={classes.chartBox}>
                <Typography
                  variant="body1"
                  align="center"
                  color="secondary"
                  className={classes.defaultText}
                  style={{ fontWeight: "bold" }}
                >
                  <FormattedMessage {...messages.portfolioA} />
                </Typography>
              </Grid>
              <Grid item container spacing={1} style={{ paddingTop: 8 }}>
                <Grid item xs={6} style={{ paddingLeft: 30 }}>
                  <Typography
                    variant="body1"
                    style={{
                      color: portfolioAColor[0],
                      fontWeight: "bold",
                    }}
                    className={classes.defaultText}
                  >
                    <FormattedMessage {...messages.fiftyPercent} />
                  </Typography>
                  <Typography
                    className={classes.customTextQuestionnaire}
                    variant="body1"
                    style={{ color: portfolioAColor[0] }}
                  >
                    <FormattedMessage
                      {...messages.returnPercent}
                      values={{
                        percent: datas1[0].value,
                      }}
                    />
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    variant="body1"
                    style={{
                      color: portfolioAColor[1],
                      fontWeight: "bold",
                    }}
                    className={classes.defaultText}
                  >
                    <FormattedMessage {...messages.fiftyPercent} />
                  </Typography>
                  <Typography
                    className={classes.customTextQuestionnaire}
                    variant="body1"
                    algin="right"
                    style={{ color: portfolioAColor[1] }}
                  >
                    {step === 4 ? (
                      <FormattedMessage
                        {...messages.returnPercent}
                        values={{
                          percent: datas1[1].value,
                        }}
                      />
                    ) : (
                      <FormattedMessage
                        {...messages.lossPercent}
                        values={{
                          percent: datas1[1].value * -1,
                        }}
                      />
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={12} style={{ height: 160 }}>
                  <BarChart
                    dataArray={datas1}
                    backgroundColorArray={portfolioAColor}
                    YMin={smallestValue}
                    YMax={biggestValue}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              container
              onClick={() => handleChoose(PB)}
              className={classes.chartContainer}
              spacing={2}
              style={{
                border: defaultValue === "Portfolio B" && ` 1px solid #929292`,
                boxShadow: defaultValue === "Portfolio B" && theme.shadows[1],
                marginTop: 10,
                borderRadius: isMobileSize ? 10 : 0,
              }}
            >
              <Grid item className={classes.chartBox}>
                <Typography
                  variant="body1"
                  align="center"
                  color="secondary"
                  style={{ fontWeight: "bold" }}
                  className={classes.defaultText}
                >
                  <FormattedMessage {...messages.portfolioB} />
                </Typography>
              </Grid>
              <Grid item container spacing={1} style={{ paddingTop: 8 }}>
                <Grid item xs={6} style={{ paddingLeft: 30 }}>
                  <Typography
                    variant="body1"
                    algin="left"
                    style={{
                      color: portfolioAColor[0],
                      fontWeight: "bold",
                    }}
                    className={classes.defaultText}
                  >
                    <FormattedMessage {...messages.oneHundredPercent} />
                  </Typography>
                  <Typography
                    className={classes.customTextQuestionnaire}
                    variant="body1"
                    algin="left"
                    style={{ color: portfolioBColor[0] }}
                  >
                    <FormattedMessage
                      {...messages.returnPercent}
                      values={{
                        percent: datas2[0].value,
                      }}
                    />
                  </Typography>
                </Grid>
                {step === 5 && (
                  <Grid item xs={6}>
                    <Typography
                      variant="body1"
                      style={{
                        color: portfolioBColor[1],
                        fontWeight: "bold",
                      }}
                      className={classes.defaultText}
                    >
                      <FormattedMessage {...messages.oneHundredPercent} />
                    </Typography>
                    <Typography
                      className={classes.customTextQuestionnaire}
                      variant="body1"
                      algin="left"
                      style={{ color: portfolioBColor[1] }}
                    >
                      <FormattedMessage
                        {...messages.lossPercent}
                        values={{
                          percent: 0,
                        }}
                      />
                    </Typography>
                  </Grid>
                )}
                <Grid item xs={12} style={{ height: 160 }}>
                  <BarChart
                    dataArray={step === 4 ? Array(datas2[0]) : datas2}
                    backgroundColorArray={portfolioBColor}
                    YMin={smallestValue}
                    YMax={biggestValue}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            item
            container
            justifyContent="center"
            alignContent="center"
            xs={12}
            style={{ paddingTop: 20, paddingBottom: 20 }}
          >
            <Grid
              item
              container
              justifyContent="center"
              alignContent="center"
              direction="column"
            >
              <Button
                variant="contained"
                color="primary"
                disabled={isDisabled}
                className={classes.nextButton}
                onClick={handleNextStep}
              >
                <Typography
                  algin="center"
                  className={classes.nomalText}
                  style={{ fontWeight: "bold" }}
                >
                  <FormattedMessage {...messages.next} />
                </Typography>
              </Button>
              <Grid item style={{ paddingTop: 10 }}></Grid>
              <Button
                variant="outlined"
                color="primary"
                onClick={backStep}
                className={classes.nextButton}
              >
                <Typography
                  algin="center"
                  style={{ fontSize: 14, fontWeight: "bold" }}
                >
                  <FormattedMessage {...messages.back} />
                </Typography>
              </Button>
            </Grid>
          </Grid>
        </GridWrapper>
      ) : (
        <Grid item container direction="column" style={{ paddingTop: 30 }}>
          <Grid item xs={12} container direction="column">
            <Typography variant="h1" algin="left" color="primary">
              <FormattedMessage {...messages.riskQuestionnaire} />
            </Typography>
          </Grid>

          <Grid item xs={12} container>
            <Grid
              item
              xs={9}
              container
              style={{
                alignContent: "flex-start",
              }}
            >
              <TypographyCustom
                variant="body2"
                color="secondary"
                style={{
                  textAlign: "left",
                  marginTop: 20,
                  marginBottom: 20,
                }}
              >
                {`0${step}.`} {title}
              </TypographyCustom>
              <Grid item container xs={12}>
                <Grid
                  item
                  xs
                  container
                  className={classes.chartContainer}
                  style={{
                    border:
                      defaultValue === "Portfolio A" &&
                      ` 1px solid ${theme.palette.secondary.main}`,
                  }}
                  onClick={() => handleChoose(PA)}
                  spacing={2}
                >
                  <Grid
                    item
                    className={classes.chartBox}
                    style={{
                      border:
                        defaultValue === "Portfolio A" &&
                        ` 1px solid ${theme.palette.secondary.main}`,
                    }}
                  >
                    <Typography
                      variant="body1"
                      align="center"
                      color="secondary"
                      style={{ fontWeight: "bold" }}
                    >
                      <FormattedMessage {...messages.portfolioA} />
                    </Typography>
                  </Grid>
                  <Grid item container spacing={1} style={{ paddingTop: 16 }}>
                    <Grid item xs={6} style={{ paddingLeft: 50 }}>
                      <Typography
                        variant="body1"
                        style={{
                          color: portfolioAColor[0],
                          fontWeight: "bold",
                        }}
                      >
                        <FormattedMessage {...messages.fiftyPercent} />
                      </Typography>
                      <Typography
                        className={classes.customTextQuestionnaire}
                        variant="body1"
                        style={{ color: portfolioAColor[0] }}
                      >
                        <FormattedMessage
                          {...messages.returnPercent}
                          values={{
                            percent: datas1[0].value,
                          }}
                        />
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      style={{
                        paddingLeft: 25,
                      }}
                    >
                      <Typography
                        variant="body1"
                        style={{
                          color: portfolioAColor[1],
                          fontWeight: "bold",
                        }}
                      >
                        <FormattedMessage {...messages.fiftyPercent} />
                      </Typography>
                      <Typography
                        className={classes.customTextQuestionnaire}
                        variant="body1"
                        algin="right"
                        style={{ color: portfolioAColor[1] }}
                      >
                        {step === 4 ? (
                          <FormattedMessage
                            {...messages.returnPercent}
                            values={{
                              percent: datas1[1].value,
                            }}
                          />
                        ) : (
                          <FormattedMessage
                            {...messages.lossPercent}
                            values={{
                              percent: datas1[1].value * -1,
                            }}
                          />
                        )}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} style={{ paddingLeft: 20, height: 200 }}>
                      <BarChart
                        dataArray={datas1}
                        backgroundColorArray={portfolioAColor}
                        YMin={smallestValue}
                        YMax={biggestValue}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid
                  item
                  xs
                  container
                  onClick={() => handleChoose(PB)}
                  className={classes.chartContainer}
                  spacing={2}
                  style={{
                    border:
                      defaultValue === "Portfolio B" &&
                      ` 1px solid ${theme.palette.secondary.main}`,
                    marginLeft: 40,
                  }}
                >
                  <Grid
                    item
                    className={classes.chartBox}
                    style={{
                      border:
                        defaultValue === "Portfolio B" &&
                        ` 1px solid ${theme.palette.secondary.main}`,
                    }}
                  >
                    <Typography
                      variant="body1"
                      align="center"
                      color="secondary"
                      style={{ fontWeight: "bold" }}
                    >
                      <FormattedMessage {...messages.portfolioB} />
                    </Typography>
                  </Grid>
                  <Grid item container spacing={1} style={{ paddingTop: 16 }}>
                    <Grid item xs={6} style={{ paddingLeft: 50 }}>
                      <Typography
                        variant="body1"
                        algin="left"
                        style={{
                          color: portfolioAColor[0],
                          fontWeight: "bold",
                        }}
                      >
                        <FormattedMessage {...messages.oneHundredPercent} />
                      </Typography>
                      <Typography
                        className={classes.customTextQuestionnaire}
                        variant="body1"
                        algin="left"
                        style={{ color: portfolioBColor[0] }}
                      >
                        <FormattedMessage
                          {...messages.returnPercent}
                          values={{
                            percent: datas2[0].value,
                          }}
                        />
                      </Typography>
                    </Grid>
                    {step === 5 && (
                      <Grid item xs={6} style={{ paddingLeft: 30 }}>
                        <Typography
                          variant="body1"
                          style={{
                            color: portfolioBColor[1],
                            fontWeight: "bold",
                          }}
                        >
                          <FormattedMessage {...messages.oneHundredPercent} />
                        </Typography>
                        <Typography
                          className={classes.customTextQuestionnaire}
                          variant="body1"
                          algin="left"
                          style={{ color: portfolioBColor[1] }}
                        >
                          <FormattedMessage
                            {...messages.lossPercent}
                            values={{
                              percent: 0,
                            }}
                          />
                        </Typography>
                      </Grid>
                    )}
                    <Grid item xs={12} style={{ paddingLeft: 20, height: 200 }}>
                      <BarChart
                        dataArray={step === 4 ? Array(datas2[0]) : datas2}
                        backgroundColorArray={portfolioBColor}
                        YMin={smallestValue}
                        YMax={biggestValue}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={3} container direction="row">
              <Grid item style={{ paddingTop: 50, paddingLeft: 30 }} xs={12}>
                <img src={image} alt={`image-${step}`} />
              </Grid>
              <Grid
                item
                container
                xs={12}
                style={{ alignContent: "flex-end", paddingLeft: 30 }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  disabled={isDisabled}
                  className={classes.nextButton}
                  onClick={handleNextStep}
                >
                  <Typography
                    algin="center"
                    style={{ fontSize: 24, fontWeight: "bold" }}
                  >
                    <FormattedMessage {...messages.next} />
                  </Typography>
                </Button>
                <Button
                  variant="outlined"
                  color="primary"
                  className={classes.backButton}
                  onClick={backStep}
                >
                  <Typography
                    algin="center"
                    style={{ fontSize: 24, fontWeight: "bold" }}
                  >
                    <FormattedMessage {...messages.back} />
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
}

ChartQuestion.propTypes = {
  classes: PropTypes.object,
  defaultValue: PropTypes.string,
  setNewValue: PropTypes.func,
  theme: PropTypes.object,
  step: PropTypes.number,
  handleNextStep: PropTypes.func,
  title: PropTypes.object,
  image: PropTypes.string,
  name: PropTypes.string,
  datas1: PropTypes.array,
  datas2: PropTypes.array,
};
