import { call, put, takeLatest, all, select } from "redux-saga/effects";
import { dispatchRequest, recurse } from "helpers/request";
import { api, config } from "environments";
import _ from "lodash";
import {
  setResourceAction,
  setOptionalResourceAction,
  setRiskToolAction,
  setRetirementToolAction,
  setLoadingAction,
  setErrorAction,
} from "./actions";
import {
  GET_RESOURCE_ACTION,
  GET_OPTIONAL_RESOURCE_ACTION,
  GET_RISK_TOOL_ACTION,
  GET_RETIREMENT_TOOL_ACTION,
} from "./constants";
import { makeGetResources } from "./selectors";

const assumptionsKey =
  "nc_assumptions,nc_education_expense_stemdegree,nc_lifestyle_replacement,nc_purchase_power_parity,nc_retirement_version,nc_risktool_version";
const localeKey = "en";
const tenantId = config.tenantId;

export function* getResources() {
  try {
    yield call(recurse, function*() {
      const url = `${api.apiHost}/resources?locale=${localeKey}&keys=${assumptionsKey}`;
      const requestOpt = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "X-TENANT-ID": config.tenantId,
          "Ocp-Apim-Subscription-Key": config.apiSubKey,
        },
      };
      const response = yield call(dispatchRequest, url, requestOpt);
      if (response.data) {
        yield put(setResourceAction(response.data));
      }
    });
  } catch (err) {
    console.log(err);
    yield put(setErrorAction({ isError: true, status: err }));
  } finally {
  }
}

export function* getOptionalResources() {
  try {
    yield call(recurse, function*() {
      const url = `${api.apiHost}/optional-resources-by-tenantId?&tenantId=${tenantId}`;
      const requestOpt = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "X-TENANT-ID": config.tenantId,
          "Ocp-Apim-Subscription-Key": config.apiSubKey,
        },
      };
      const response = yield call(dispatchRequest, url, requestOpt);
      if (response.data) {
        yield put(setOptionalResourceAction(response.data));
      }
    });
  } catch (err) {
    console.log(err);
    yield put(setErrorAction({ isError: true, status: err }));
  } finally {
  }
}

export function* getRetirementTool(action) {
  try {
    const { payload } = action;
    const resources = yield select(makeGetResources());
    const retVersion = _.find(resources, { key: "nc_retirement_version" })
      ?.value?.data;
    const UUID = _.find(retVersion, { key: "UUID" })?.value;
    const PRODUCTID = _.find(retVersion, { key: "PRODUCTID" })?.value;
    const PRODUCT_VERSION = _.find(retVersion, { key: "PRODUCT_VERSION" })
      ?.value;
    const WORKFLOW_NUMBER = _.find(retVersion, { key: "WORKFLOW_NUMBER" })
      ?.value;

    const iputPayload = {
      base: {
        CurrentSavings: payload?.currentSavings,
        CurrentAge: payload?.currentAge,
        RetirementAge: payload?.retirementAge,
        InflationRate: payload?.inflationRate,
        MonthlyExpense: payload?.monthlyExpense,
        CountryForRetirement: payload?.country,
        ReturnRate: payload?.growthRate,
        MonthlyIncome: payload?.monthlyIncome,
        SavingsPerMonth: payload?.plannedSavings,
        Lifestyle: payload?.lifestyle,
      },
      uuid: UUID,
      productId: PRODUCTID,
      productVersion: PRODUCT_VERSION,
      workflowNo: WORKFLOW_NUMBER,
    };

    const url = `${api.apiHostQe}/premium-calculator?tenantId=${tenantId}`;
    const requestOpt = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-TENANT-ID": config.tenantId,
        "Ocp-Apim-Subscription-Key": config.apiSubKeyQe,
      },
      body: JSON.stringify(iputPayload),
    };

    const response = yield call(dispatchRequest, url, requestOpt);
    if (response.success === false) {
      yield put(
        setErrorAction({ isError: true, status: response.result.error * 1 })
      );
    }
    if (response) {
      yield put(setRetirementToolAction(response));
    }
  } catch (err) {
    console.log(err);
    yield put(setErrorAction({ isError: true, status: err }));
  } finally {
  }
}

export function* getRiskTool(action) {
  try {
    yield put(setLoadingAction(true));
    const { payload } = action;
    const resources = yield select(makeGetResources());
    const riskVersion = _.find(resources, { key: "nc_risktool_version" })?.value
      ?.data;
    const UUID = _.find(riskVersion, { key: "UUID" })?.value;
    const PRODUCTID = _.find(riskVersion, { key: "PRODUCTID" })?.value;
    const PRODUCT_VERSION = _.find(riskVersion, { key: "PRODUCT_VERSION" })
      ?.value;
    const WORKFLOW_NUMBER = _.find(riskVersion, { key: "WORKFLOW_NUMBER" })
      ?.value;

    const iputPayload = {
      base: {
        ...payload,
      },
      uuid: UUID,
      productId: PRODUCTID,
      productVersion: PRODUCT_VERSION,
      workflowNo: WORKFLOW_NUMBER,
    };
    const url = `${api.apiHostQe}/premium-calculator?tenantId=${tenantId}`;
    const requestOpt = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-TENANT-ID": config.tenantId,
        "Ocp-Apim-Subscription-Key": config.apiSubKeyQe,
      },
      body: JSON.stringify(iputPayload),
    };

    const response = yield call(dispatchRequest, url, requestOpt);
    if (response.success === false) {
      yield put(
        setErrorAction({ isError: true, status: response.result.error * 1 })
      );
    }
    if (response) {
      yield put(setRiskToolAction(response));
    }
  } catch (err) {
    yield put(setLoadingAction(false));
    yield put(setErrorAction({ isError: true, status: err }));
  } finally {
    yield put(setLoadingAction(false));
  }
}

export default function* riskToolPageSaga() {
  yield all([takeLatest(GET_RESOURCE_ACTION, getResources)]);
  yield all([takeLatest(GET_OPTIONAL_RESOURCE_ACTION, getOptionalResources)]);
  yield all([takeLatest(GET_RETIREMENT_TOOL_ACTION, getRetirementTool)]);
  yield all([takeLatest(GET_RISK_TOOL_ACTION, getRiskTool)]);
}
