import { MenuItem } from "@material-ui/core";
import styled from "styled-components";

const MenuItemWrapper = styled(MenuItem)`
  && {
    color: ${(props) => props.theme.palette.secondary.main};
    font-size: 2.5rem;
    @media (max-width: 768px) {
      font-size: ${(props) => props.theme.spacing(3)}px;
    }
    @media (max-width: 600px) {
      font-size: 16px;
    }
  }
`;

export default MenuItemWrapper;
