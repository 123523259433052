import React from "react";
import { Doughnut } from "react-chartjs-2";
import { Box } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";

export default function DoughnutChart({
  dataArray,
  backgroundColorArray,
  typeRiskProfile,
  isMobileSize,
}) {
  const labels = dataArray?.map((data) => data.label);
  const datas = dataArray?.map((data) => data.value);

  const centerLabel = {
    id: centerLabel,
    afterDraw(chart, args, options) {
      const {
        ctx,
        chartArea: { width, height },
      } = chart;
      const customHeightText1 = isMobileSize
        ? height / 2 - 10
        : height / 2 - 20;
      const customHeightText2 = isMobileSize
        ? height / 2 + 10
        : height / 2 + 30;
      const size = isMobileSize ? "20px" : "30pt";
      ctx.save();
      ctx.textAlign = "center";
      ctx.textBaseline = "middle";
      ctx.fillStyle = `${isMobileSize ? "#2067AD" : backgroundColorArray[0]
        }`;
      ctx.font = `bold ${size} Arial`;
      ctx.fillText(`${typeRiskProfile}`, width / 2, customHeightText1);
      ctx.fillText("Risk Profile", width / 2, customHeightText2);
      ctx.restore();
    },
  };

  const data = {
    labels: labels,
    datasets: [
      {
        data: datas,
        backgroundColor: backgroundColorArray,
        borderColor: backgroundColorArray,
      },
    ],
    options: {
      showScale: true,
      weight: 0,
      cutout: "80%",
      plugins: {
        legend: {
          display: false,
        },
      },
    },
    plugins: [centerLabel],
  };

  return (
    <Box width={isMobileSize ? "175px" : "80%"} style={{ margin: "auto" }}>
      <Doughnut
        data={data}
        options={data.options}
        plugins={data.plugins}
      />
    </Box>
  );
}
