import { Grid } from "@material-ui/core";
import styled from "styled-components";

const windowHeight = () => {
  const doc = document.documentElement
  doc.style.setProperty('--window-height', `${window.innerHeight}px`)
}
window.addEventListener('resize', windowHeight);
windowHeight();

const GridWrapper = styled(Grid)`
  && {
    @media only screen and (min-width: 1400px) {
      height: 100%;
    }
    @media (max-width: 600px) {
      height: ${window.innerHeight}px;
    }
  }
`;

export default GridWrapper;