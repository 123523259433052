import React from 'react';
import Typography from 'components/Typography';
import { Button, Box, Grid } from '@material-ui/core';
import ArrowRight from 'images/arrow-right.svg';
import { useTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const TypographyWrapper = styled(Typography)`
  && {
    text-transform: initial;
    @media (max-width: 768px) {
      font-size: 1.4rem;
    }
  }
`;

const BoxWrapper = styled(Box)`
  && {
    min-height: 150px;
    @media (max-width: 768px) {
      min-height: 100px;
    }
  }
`;

const GridWrapper = styled(Grid)`
  && {
    @media (max-width: 768px) {
      width: 100%;
    }
  }
`;
export default function RoundedBox({ title }) {
  const theme = useTheme();
  return (
    <GridWrapper item>
      <BoxWrapper
        p={3}
        maxWidth={390}
        bgcolor="white"
        borderRadius={24}
        boxShadow="0px 8px 38px rgba(0, 0, 0, 0.2)">
        <TypographyWrapper variant="h6" weight={300} color="primary">
          {title}
        </TypographyWrapper>
        <Button
          onClick={() => window.open(`${window.location.origin}/get-advice`)}
          variant="text"
          theme={theme}
          endIcon={<img src={ArrowRight} alt="go next" />}>
          <TypographyWrapper theme={theme} variant="h6" color="secondary">
            Take your first step, talk to us
          </TypographyWrapper>
        </Button>
      </BoxWrapper>
    </GridWrapper>
  );
}

RoundedBox.propTypes = {
  title: PropTypes.string,
};
