/**
 *
 * Typography
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Typography as BaseTypography } from '@material-ui/core';

const Typography = styled(props => {
  const { weight, ...baseProps } = props;
  return (
    <BaseTypography
      color="textPrimary"
      {...baseProps}
      classes={{
        gutterBottom: 'gutter-bottom',
        ...(props.classes ? props.classes : undefined),
      }}
    />
  );
})`
  && {
    ${({ weight }) =>
      weight &&
      `
    font-weight: ${weight};
`}

    ${({ mb }) =>
      mb &&
      `
    margin-bottom: ${mb}
`}
  }

  &.gutter-bottom {
    margin-bottom: 0.75em;
  }
`;

Typography.propTypes = {
  weight: PropTypes.oneOf([300, 400, 500, 600, 700]),
};

export default Typography;
