export const DEFAULT_ACTION = "app/RiskToolPage/DEFAULT_ACTION";

export const GET_RESOURCE_ACTION = "app/RiskToolPage/GET_RESOURCE_ACTION";
export const SET_RESOURCE_ACTION = "app/RiskToolPage/SET_RESOURCE_ACTION";

export const GET_OPTIONAL_RESOURCE_ACTION =
  "app/RiskToolPage/GET_OPTIONAL_RESOURCE_ACTION";
export const SET_OPTIONAL_RESOURCE_ACTION =
  "app/RiskToolPage/SET_OPTIONAL_RESOURCE_ACTION";

export const SET_RETIREMENT_ACTION = "app/RiskToolPage/SET_RETIREMENT_ACTION";

export const GET_RISK_TOOL_ACTION = "app/RiskToolPage/GET_RISK_TOOL_ACTION";
export const SET_RISK_TOOL_ACTION = "app/RiskToolPage/SET_RISK_TOOL_ACTION";

export const GET_RETIREMENT_TOOL_ACTION =
  "app/RiskToolPage/GET_RETIREMENT_TOOL_ACTION";
export const SET_RETIREMENT_TOOL_ACTION =
  "app/RiskToolPage/SET_RETIREMENT_TOOL_ACTION";

export const SET_LOADING_ACTION = "app/RiskToolPage/SET_LOADING_ACTION";
export const SET_ERROR_ACTION = "app/RiskToolPage/SET_ERROR_ACTION";

export const SET_STEP_ACTION = "app/RiskToolPage/SET_STEP_ACTION";
export const SET_RISK_ACTION = "app/RiskToolPage/SET_RISK_ACTION";

export const CHECK_STATUS = [
  { 400: "Bad Request" },
  { 401: "Unauthorized" },
  { 403: "Forbidden" },
  { 404: "Not Found" },
  { 500: "Internal Server Error" },
  { 502: "Bad Gateway" },
  { 503: "Service Unavailable" },
  { 504: "Gateway Timeout" },
];
export const RISK_OPTIONS = [
  { key: "r1", value: 0.01, label: "1%" },
  { key: "r2", value: 0.03, label: "3%" },
  { key: "r3", value: 0.05, label: "5%" },
  { key: "r4", value: 0.07, label: "7%" },
  { key: "r5", value: 0.09, label: "9%" },
];

export const SELLING_RATE_OPTIONS = [
  { key: "s1", value: 0, label: "0%" },
  { key: "s2", value: 0.25, label: "25%" },
  { key: "s3", value: 0.5, label: "50%" },
  { key: "s4", value: 0.75, label: "75%" },
  { key: "s5", value: 1.0, label: "100%" },
];

// export const VIRTUAL_URLS = {
//   0: {
//     page_url_window_url: window.location + "questionnaire",
//     page_pageInfo_pageName: "questionnaire",
//   },
//   1: {
//     page_url_window_url: window.location + "expected-return",
//     page_pageInfo_pageName: "expected-return",
//   },
//   2: {
//     page_url_window_url: window.location + "minimum-return",
//     page_pageInfo_pageName: "minimum-return",
//   },
//   3: {
//     page_url_window_url: window.location + "5-years-decision",
//     page_pageInfo_pageName: "5-years-decision",
//   },
//   4: {
//     page_url_window_url: window.location + "portfolio-selection-1",
//     page_pageInfo_pageName: "portfolio-selection-1",
//   },
//   5: {
//     page_url_window_url: window.location + "portfolio-selection-2",
//     page_pageInfo_pageName: "portfolio-selection-2",
//   },
//   6: {
//     page_url_window_url: window.location + "result",
//     page_pageInfo_pageName: "result",
//   },
// };
