import React, { useEffect, useState, useContext } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { createStructuredSelector } from "reselect";
import { compose } from "redux";
import { useTheme } from "@material-ui/core/styles";
import { useInjectSaga } from "utils/injectSaga";
import { useInjectReducer } from "utils/injectReducer";
import { convertToNumberWithoutCommas } from "utils/form";
import { injectIntl } from "react-intl";
import {
  getResourceAction,
  getOptionalResourceAction,
  setRetiremnetAction,
  getRetirementToolAction,
  getRiskToolAction,
  setStepAction,
  setRiskAction,
} from "./actions";
import makeSelectRiskToolPage, {
  makeGetResources,
  makeGetOptionalResources,
  makeSelectRetirement,
  makeSelectRetirementTool,
  makeSelectRiskTool,
  makeSelectLoading,
  makeSelectError,
  makeSelectStep,
  makeSelectRisk,
} from "./selectors";
import reducer from "./reducer";
import saga from "./saga";
import PlanningForm from "./components/PlanningForm";
import { Grid } from "@material-ui/core";
import Questionnaire from "./components/Questionnaire";
// import { VIRTUAL_URLS } from "./constants";
import MobilePlanningForm from "./components/MobilePlanning";
import DeviceContext from "components/DetectDevice";
import NotificationDialog from "components/NotificationDialog";
// import { UtagLoader } from "helpers/utagLoader";

export function RiskToolPage({
  resources,
  getResources,
  optionalResources,
  getOptionalResources,
  retirement,
  setRetiremnetAction,
  getRetirementToolAction,
  getRiskToolAction,
  riskTool,
  setRiskAction,
  isLoading,
  error,
  intl,
  step,
  setStep,
  riskData,
}) {
  useInjectReducer({ key: "riskToolPage", reducer });
  useInjectSaga({ key: "riskToolPage", saga });
  const theme = useTheme();
  const [isLanding, setIsLanding] = useState(true);

  const planningRetirement = {
    country: retirement?.country,
    currency: retirement?.currency,
    currentAge: retirement?.currentAge,
    currentSavings: retirement?.currentSavings,
    growthRate: retirement?.growthRate,
    inflationRate: retirement?.inflationRate,
    lifestyle: retirement?.lifestyle,
    monthlyExpense: retirement?.monthlyExpense,
    monthlyIncome: retirement?.monthlyIncome,
    plannedSavings: retirement?.plannedSavings,
    retirementAge: retirement?.retirementAge,
  };

  const questionRetirement = {
    expectedAnnualReturn: riskData?.ExpectedReturns,
    minimumAnnualReturn: riskData?.MinExpectedReturns,
    percentageOfSellingRate: riskData?.InvestTemperament,
    portfolio1: riskData?.AttitudeUncertainty,
    portfolio2: riskData?.AttitudeLosses,
  };

  let isMobileSize = useContext(DeviceContext);

  const handleSubmitPlanningForm = (values) => {
    for (const value in values) {
      if (value === "growthRate" || value === "inflationRate") {
        values[value] =
          (convertToNumberWithoutCommas(values[value]) / 100).toFixed(4) * 1;
      } else {
        values[value] = convertToNumberWithoutCommas(values[value]);
      }
    }
    setRetiremnetAction(values);
    getRetirementToolAction(values);
    setStep(1);
  };

  const handleSubmitShowResult = () => {
    setShowResult(true);
  };

  const handleRetake = () => {
    location.reload();
  };

  const handleLoginZIO = () => {
    window.open(
      "https://online.zurichinternationalsolutions.com/login.aspx?ReturnUrl=%2f",
      "_blank"
    );
  };

  const handleStart = () => {
    setIsLanding(false);
  };

  const handleBackStep = () => {
    if (step > 0) {
      setStep(step - 1);
    }

    if (step === 0) {
      handleStart(true);
    }
  };

  useEffect(() => {
    getResources();
    getOptionalResources();
  }, []);

  // useEffect(() => {
  //   UtagLoader().then(() => {
  //     window.dice({
  //       event_eventInfo_type: "pageview-virtual",
  //       page_url_window_url: VIRTUAL_URLS[step].page_url_window_url,
  //       page_pageInfo_pageName: VIRTUAL_URLS[step].page_pageInfo_pageName,
  //       page_category_primaryCategory: process.env.API_HOST,
  //       page_url_canonical_url: VIRTUAL_URLS[step].page_url_window_url,
  //     });
  //   });
  // }, [step]);

  return (
    <>
      <Grid item theme={theme}>
        <Helmet>
          <title>Attitude to Risk</title>
          <meta name="description" content="Attitude to Risk tools" />
        </Helmet>

        {step === 0 ? (
          <>
            {isMobileSize ? (
              <MobilePlanningForm
                resources={resources}
                optionalResources={optionalResources}
                initialValues={planningRetirement}
                handleSubmitForm={handleSubmitPlanningForm}
                intl={intl}
                isLanding={isLanding}
                handleStart={handleStart}
              />
            ) : (
              <PlanningForm
                resources={resources}
                optionalResources={optionalResources}
                initialValues={planningRetirement}
                handleSubmitForm={handleSubmitPlanningForm}
                intl={intl}
              />
            )}
          </>
        ) : (
          <Questionnaire
            intl={intl}
            initialValues={questionRetirement}
            retirementData={retirement}
            getRiskToolAction={getRiskToolAction}
            riskToolData={riskTool}
            handleShowResult={handleSubmitShowResult}
            isLoading={isLoading}
            isMobileSize={isMobileSize}
            handleRetake={handleRetake}
            handleLoginZIO={handleLoginZIO}
            backStep={handleBackStep}
            setStep={setStep}
            step={step}
            riskData={riskData}
            setRiskAction={setRiskAction}
            error={error}
          />
        )}
      </Grid>

      <NotificationDialog open={error.isError} status={error.status} />
    </>
  );
}

RiskToolPage.propTypes = {
  dispatch: PropTypes.func.isRequired,
  appStyles: PropTypes.object,
};

const mapStateToProps = createStructuredSelector({
  retirementPage: makeSelectRiskToolPage(),
  resources: makeGetResources(),
  optionalResources: makeGetOptionalResources(),
  retirement: makeSelectRetirement(),
  retirementTool: makeSelectRetirementTool(),
  riskTool: makeSelectRiskTool(),
  isLoading: makeSelectLoading(),
  error: makeSelectError(),
  step: makeSelectStep(),
  riskData: makeSelectRisk(),
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    getResources: () => dispatch(getResourceAction()),
    getOptionalResources: () => dispatch(getOptionalResourceAction()),
    setRetiremnetAction: (data) => dispatch(setRetiremnetAction(data)),
    getRetirementToolAction: (data) => dispatch(getRetirementToolAction(data)),
    getRiskToolAction: (data) => dispatch(getRiskToolAction(data)),
    setStep: (data) => dispatch(setStepAction(data)),
    setRiskAction: (data) => dispatch(setRiskAction(data)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, injectIntl)(RiskToolPage);
