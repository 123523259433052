import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  html {
    font-size: 62.5%;
  }

  /* use inherit font from Zurich's website
  /*
  body.fontLoaded {
    font-family: 'ZurichSans', 'Helvetica Neue', Helvetica, Arial, sans-serif; 
    font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    
  } 
  */
  /* #app {
    width: 100%;
    height: 100%;
  } */
`;
