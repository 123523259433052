import { Button, Grid, Snackbar } from "@material-ui/core";
import LoadingDialog from "components/LoadingDialog";
import RiskToolPage from "containers/RiskToolPage/Loadable";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { Route, Switch } from "react-router-dom";
import { compose } from "redux";
import { createStructuredSelector } from "reselect";
import injectSaga from "utils/injectSaga";
import GlobalStyle from "../../global-styles";
import "../../global-styles.css";
import saga from "./saga";
import {
  makeSelectLoading,
  makeSelectLoadingMessage,
  makeSelectAppUpdate,
} from "./selectors";
import _ from "lodash";
import DeviceContext from "components/DetectDevice";
import { useMediaQuery } from "react-responsive";

function App({ loading, loadingMessage, updateStatus }) {
  const isMobile = useMediaQuery({ maxWidth: 768 });

  const handleErrorSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(!open);
    window.location.replace(`${window.location.pathname}`);
  };
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (updateStatus) {
      setOpen(true);
    }
  }, [updateStatus]);

  return (
    <Grid container direction="column" wrap="nowrap">
      <DeviceContext.Provider value={isMobile}>
        {/* USE STATIC HTML RENDER FOR PRODUCTION */}

        <Switch>
          <Route
            path="/"
            render={(routeProps) => <RiskToolPage {...routeProps} />}
          />
        </Switch>

        <LoadingDialog
          isLoading={loading}
          message={
            loadingMessage.id ? <FormattedMessage {...loadingMessage} /> : null
          }
        />

        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          open={open}
          autoHideDuration={300000}
          onClose={handleErrorSnackbarClose}
          message="A new version of Attitude To Risk is available"
          action={
            <>
              <Button
                size="small"
                onClick={handleErrorSnackbarClose}
                style={{ color: "#EED369" }}
              >
                UPDATE
              </Button>
            </>
          }
        ></Snackbar>
        <GlobalStyle />
      </DeviceContext.Provider>
    </Grid>
  );
}

App.propTypes = {
  loading: PropTypes.bool,
  loadingMessage: PropTypes.object,
};

const mapStateToProps = createStructuredSelector({
  loading: makeSelectLoading(),
  loadingMessage: makeSelectLoadingMessage(),
  updateStatus: makeSelectAppUpdate(),
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withSaga = injectSaga({ key: "app", saga });

export default compose(withSaga, withConnect)(App);
