import {
  DEFAULT_ACTION,
  GET_RESOURCE_ACTION,
  SET_RESOURCE_ACTION,
  GET_OPTIONAL_RESOURCE_ACTION,
  SET_OPTIONAL_RESOURCE_ACTION,
  SET_RETIREMENT_ACTION,
  GET_RISK_TOOL_ACTION,
  SET_RISK_TOOL_ACTION,
  GET_RETIREMENT_TOOL_ACTION,
  SET_RETIREMENT_TOOL_ACTION,
  SET_LOADING_ACTION,
  SET_ERROR_ACTION,
  SET_STEP_ACTION,
  SET_RISK_ACTION,
} from "./constants";

export function defaultAction() {
  return {
    type: DEFAULT_ACTION,
  };
}

export function setRiskAction(payload) {
  return {
    type: SET_RISK_ACTION,
    payload,
  };
}

export function setResourceAction(payload) {
  return {
    type: SET_RESOURCE_ACTION,
    payload,
  };
}
export function getResourceAction() {
  return {
    type: GET_RESOURCE_ACTION,
  };
}

export function setOptionalResourceAction(payload) {
  return {
    type: SET_OPTIONAL_RESOURCE_ACTION,
    payload,
  };
}
export function getOptionalResourceAction() {
  return {
    type: GET_OPTIONAL_RESOURCE_ACTION,
  };
}

export function setRetiremnetAction(payload) {
  return {
    type: SET_RETIREMENT_ACTION,
    payload,
  };
}

export function setRiskToolAction(payload) {
  return {
    type: SET_RISK_TOOL_ACTION,
    payload,
  };
}
export function getRiskToolAction(payload) {
  return {
    type: GET_RISK_TOOL_ACTION,
    payload,
  };
}

export function setRetirementToolAction(payload) {
  return {
    type: SET_RETIREMENT_TOOL_ACTION,
    payload,
  };
}
export function getRetirementToolAction(payload) {
  return {
    type: GET_RETIREMENT_TOOL_ACTION,
    payload,
  };
}

export function setLoadingAction(payload) {
  return {
    type: SET_LOADING_ACTION,
    payload,
  };
}

export function setErrorAction(payload) {
  return {
    type: SET_ERROR_ACTION,
    payload,
  };
}

export function setStepAction(payload) {
  return {
    type: SET_STEP_ACTION,
    payload,
  };
}
