import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import styled from 'styled-components';

const ErrorBodyWrapper = styled(Grid)`
  && {
    padding: ${props => props.theme.spacing(0.5, 1)};
    background: ${props => props.theme.palette.error.main};
    color: ${props => props.theme.palette.primary.contrastText};
    display: flex;
    align-items: center;
    width: fit-content;
    margin-top: ${props => props.theme.spacing(0.5)}px;
    font-weight: 300;
    max-height: 80px;
    font-size: 1.8rem;
  }
`;

function ErrorWrapper(props) {
  const theme = useTheme();
  const { message } = props;
  return <ErrorBodyWrapper theme={theme}>{message}</ErrorBodyWrapper>;
}

ErrorWrapper.propTypes = {
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default ErrorWrapper;
