import { Button } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import MuiTypography from 'components/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const ButtonWrapper = styled(Button)`
  && {
    margin-top: ${props => props.theme.spacing(4)}px;
    margin-bottom: ${props => props.theme.spacing(8)}px;
    border-radius: ${props => props.theme.spacing(3.5)}px;
    text-transform: inherit;
    width: ${props => props.theme.spacing(28)}px;
    height: ${props => props.theme.spacing(7)}px;
    @media (max-width: 768px) {
      width: 165px;
      height: 44px;
    }
  }
`;

const Typography = styled(MuiTypography)`
  && {
    font-size: 1.8rem;
    color: ${props => props.theme.palette.primary.contrastText};
    @media (max-width: 768px) {
      font-size: 1.4rem;
    }
  }
`;

function Buttons(props) {
  const { type, disabled, title } = props;
  const theme = useTheme();

  return (
    <ButtonWrapper
      type={type}
      disabled={disabled}
      theme={theme}
      variant="contained"
      color="primary"
      endIcon={
        <ArrowForwardIosIcon
          fontSize="inherit"
          style={{
            color: theme.palette.primary.contrastText,
          }}
        />
      }>
      <Typography theme={theme} weight={400}>
        {title}
      </Typography>
    </ButtonWrapper>
  );
}

Buttons.propTypes = {
  type: PropTypes.string,
  disabled: PropTypes.bool,
  title: PropTypes.string,
};

export default Buttons;
