import { fromJS } from "immutable";

import {
  DEFAULT_ACTION,
  SET_APP_LOADING_ACTION,
  SET_AUTH_TOKEN_ACTION,
  SET_VERIFY_TOKEN_ACTION,
  SET_APP_UPDATE_ACTION,
} from "./constants";

const defaultState = {
  loading: false,
  loadingMessage: {},
  auth: { token: sessionStorage.getItem("token") || null },
  tokenVerify: {},
  updateStatus: false,
};

// The initial state of the App
const initialState = fromJS({ ...defaultState });

function appReducer(state = initialState, action) {
  switch (action.type) {
    case SET_APP_LOADING_ACTION:
      return state
        .set("loading", action.payload.loading)
        .set("loadingMessage", fromJS({ ...action.payload.message }));
    case SET_AUTH_TOKEN_ACTION:
      sessionStorage.setItem("token", action.payload);
      return state.setIn(["auth", "token"], action.payload);
    case SET_VERIFY_TOKEN_ACTION:
      return state.set("tokenVerify", action.payload);
    case SET_APP_UPDATE_ACTION:
      return state.set("updateStatus", action.payload);
    case DEFAULT_ACTION:
    default:
      return state;
  }
}

export default appReducer;
