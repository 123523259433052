import envDev from './environment.dev';
import envProd from './environment.prod';

let env;

switch (process.env.NODE_ENV) {
  case 'production':
    env = envProd;
    break;
  default:
    env = envDev;
    break;
}

export const { api, config } = env;
