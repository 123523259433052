import { createStyles, makeStyles } from "@material-ui/core/styles";
import { color } from "styles/color";

const useMobileStyles = makeStyles((theme) =>
  createStyles({
    selectTextField: {
      fontSize: "16px",
      color: theme.palette.primary.main,
      height: 25,
      width: 134,
      backgroundColor: color.white,
      borderRadius: "24px",
      fontWeight: "bold",
      '&.MuiSelect-outlined': {
        marginTop: theme.spacing(0.7),
        backgroundColor: 'transparent',
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderRadius: "24px",
        },
        '&:hover fieldset': {
          borderRadius: "24px",
          borderColor: theme.palette.secondary.main,
        },
        '&.Mui-focused fieldset': {
          borderRadius: "24px",
          borderColor: theme.palette.secondary.main,
        },
        '&.Mui-error fieldset': {
          borderColor: theme.palette.error.main,
        }
      },
    },
    placeholderTextField: {
      fontSize: "16px",
      color: color.grey,
      height: 25,
      width: 134,
      backgroundColor: color.white,
      borderRadius: "24px",
      marginTop: 8,
      '&.MuiSelect-outlined': {
        marginTop: theme.spacing(0.7),
        backgroundColor: 'transparent',
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderRadius: "24px",

        },
        '&:hover fieldset': {
          borderRadius: "24px",
          borderColor: theme.palette.secondary.main,
        },
        '&.Mui-focused fieldset': {
          borderRadius: "24px",
          borderColor: theme.palette.secondary.main,
        },
        '&.Mui-error fieldset': {
          borderColor: theme.palette.error.main,
        }
      },
    },
    selectTextFieldQuestion: {
      fontSize: "16px",
      color: "#000",
      height: 25,
      width: '100%',
      backgroundColor: color.white,
      borderRadius: "24px",
      '&.MuiSelect-outlined': {
        marginTop: theme.spacing(0.7),
        backgroundColor: 'transparent',
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderRadius: "24px",

        },
        '&:hover fieldset': {
          borderRadius: "24px",
          borderColor: theme.palette.secondary.main,
        },
        '&.Mui-focused fieldset': {
          borderRadius: "24px",
          borderColor: theme.palette.secondary.main,
        },
        '&.Mui-error fieldset': {
          borderColor: theme.palette.error.main,
        }
      },
    },
    selectTextFieldQuestionBlank: {
      fontSize: "16px",
      color: color.grey,
      height: 25,
      width: '100%',
      backgroundColor: color.white,
      borderRadius: "24px",
      '&.MuiSelect-outlined': {
        marginTop: theme.spacing(0.7),
        backgroundColor: 'transparent',
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderRadius: "24px",

        },
        '&:hover fieldset': {
          borderRadius: "24px",
          borderColor: theme.palette.secondary.main,
        },
        '&.Mui-focused fieldset': {
          borderRadius: "24px",
          borderColor: theme.palette.secondary.main,
        },
        '&.Mui-error fieldset': {
          borderColor: theme.palette.error.main,
        }
      },
    },
    imageQuestionnaire: {
      width: 142,
      height: 142,
    },
    customInput: {
      fontSize: "16px",
      color: theme.palette.primary.main,
      minHeight: 25,
      maxWidth: 134,
      backgroundColor: color.white,
      borderRadius: "24px",
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          borderRadius: "24px",
        },
      },
      "& .MuiInputBase-input.Mui-disabled": {
        color: theme.palette.primary.main,
        opacity: 1,
        backgroundColor: color.white,
      },
    },
    customWidth: {
      width: 150,
      margin: "0 8px 0 0",
      "& > .MuiInput-underline.Mui-error:after": {
        border: `1px solid ${theme.palette.error.main}`,
        height: theme.spacing(5),
        marginLeft: theme.spacing(-1),
      },
    },
    customWidthSelect: {
      minWidth: 134,
      margin: theme.spacing(0, 1),
      paddingBottom: theme.spacing(1),
      "& > .MuiInput-underline.Mui-error:after": {
        border: `1px solid ${theme.palette.error.main}`,
        height: theme.spacing(5),
        marginLeft: theme.spacing(-1),
        "@media (max-width: 768px)": {
          height: theme.spacing(4),
        },
      },
    },
    customInputBox: {
      padding: 0,
      fontSize: "16px",
      color: color.grey,
      fontWeight: 300,
      minWidth: 50,
      maxWidth: 80,
      height: 27,
      borderRadius: 4,
    },
    submitButton: {
      margin: theme.spacing(2, 0, 2, 0),
      marginTop: "20px",
      borderRadius: 32,
      textTransform: "none",
    },
    titleText: {
      fontSize: "20px",
      fontWeight: 700,
      textAlign: "center",
      marginTop: 27,
    },
    defaultText: {
      fontSize: "16px",
      lineHeight: 1.5,
    },
    nomalText: {
      fontSize: "14px",
    },
    chartText: {
      fontSize: "14.5px",
    },
    chartContainer: {
      backgroundColor: "#ECEEEF",
      padding: "16px 0px",
      width: '80%',
      height: 'auto',
    },
    chartBox: {
      padding: 8,
      backgroundColor: color.white,
      borderRadius: "32px",
      margin: "auto",
      width: "70%",
    },
    customTextSize: {
      fontSize: "12px",
    },
    simulateImgSize: {
      width: "28px",
      height: "28px",
    },
    moreIcon: {
      width: "20px",
      height: "20px",
    },
    customTextQuestionnaire: {
      fontSize: "9px",
      fontWeight: 600,
    },
    nextButton: {
      width: "100%",
      height: 41,
      width: 235,
      borderRadius: 30,
      fontSize: 20,
      fontWeight: 700,
      textTransform: "none",
    },
    nextEndButton: {
      width: "5%",
      height: "10%",
      borderRadius: 15,
      textTransform: "none",
      fontWeight: 700,
      backgroundColor: theme.palette.secondary.main,
      color: color.white,
    },
    customExpanded: {
      height: 42,
    },
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      width: '90%',
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2),
      borderRadius: 5,
    },
    labelText: {
      fontSize: "10px",
    }
  })
);
export default useMobileStyles;
