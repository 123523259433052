/**
 *
 * NotificationDialog
 *
 */

import React from "react";
import styled from "styled-components";
import { Dialog, Typography, Button, Box, Grid } from "@material-ui/core";
import Caution from "../../images/caution.svg";
import { useTheme } from "@material-ui/core/styles";
import { useState } from "react";
import { useEffect } from "react";

export const StyledDialog = styled((props) => (
  <Dialog
    {...props}
    classes={{
      paper: "paper",
    }}
  />
))`
  && {
    & .paper {
      border-radius: 16px;
    }
  }
`;

export const CustomButton = styled(Button)`
  && {
    height: 3rem;
    color: #fff;
    border-radius: 7px 7px 7px 7px;
  }
`;

export default function NotificationDialog({ open, status }) {
  const [isOpen, setIsOpen] = useState(open);

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  const handleOnClick = () => {
    window.location.replace("/");
    setIsOpen(false);
  };
  const theme = useTheme();
  const defaultMessage = `Looks like something went wrong. \n Can we try this again?\n Please click the below button.`;

  return (
    <StyledDialog open={isOpen} maxWidth="xs">
      <Box p={2}>
        <Grid
          container
          direction="column"
          spacing={3}
          alignItems="center"
          justifyContent="center"
        >
          <Grid item>
            <img src={Caution} style={{ width: "5rem", marginTop: "3rem" }} />
          </Grid>
          <Grid item xs={12} style={{ padding: "1rem 4rem" }}>
            <Typography
              variant="body1"
              align="center"
              style={{ fontWeight: 700, fontSize: "16px" }}
            >
              Oops, sorry!
            </Typography>
            <Typography
              variant="body1"
              align="center"
              style={{
                fontSize: "13px",
                fontWeight: 500,
                whiteSpace: "pre-wrap",
              }}
            >
              {defaultMessage}
            </Typography>
          </Grid>
          <Grid item>
            <CustomButton
              theme={theme}
              variant="contained"
              style={{
                backgroundColor: theme.palette.primary.main,
                marginBottom: "3rem",
                width: "23rem",
              }}
              onClick={handleOnClick}
            >
              <Typography
                align="center"
                weight={500}
                variant="caption"
                style={{ color: "white" }}
              >
                Try Again
              </Typography>
            </CustomButton>
          </Grid>
        </Grid>
      </Box>
    </StyledDialog>
  );
}
