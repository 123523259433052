import React from 'react';
import { TextField } from '@material-ui/core';
import { useTheme, makeStyles, createStyles } from '@material-ui/core/styles';
import styled from 'styled-components';

const useStyles = makeStyles(theme =>
  createStyles({
    customInput: {
      padding: 0,
      fontSize: '2.5rem',
      color: theme.palette.secondary.main,
      fontWeight: 300,
      minWidth: 200,
    },
  }),
);

const TextFieldWrapper = styled(TextField)`
  && {
    margin: ${props => props.theme.spacing(0, 0.5)};
    width: ${props => props.theme.spacing(25)}px;
  }
`;

function TextFieldCustom(props) {
  const theme = useTheme();
  const classes = useStyles();

  return (
    <TextFieldWrapper
      {...props}
      theme={theme}
      inputProps={{
        className: classes.customInput,
      }}
    />
  );
}

export default TextFieldCustom;
