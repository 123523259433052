import React, { useContext } from "react";
import { Grid, Typography, Button } from "@material-ui/core";
import { MenuItemWrapper, GridWrapper } from "components";
import { TextField } from "@material-ui/core";
import { PropTypes } from "prop-types";
import useStyles from "../styles";
import useMobileStyles from "../mobileStyles";
import { useTheme } from "@material-ui/core/styles";
import { FormattedMessage } from "react-intl";
import messages from "../../messages";
import _ from "lodash";
import styled from "styled-components";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import DeviceContext from "components/DetectDevice";
import { color } from "styles/color";

const TypographyCustom = styled(Typography)`
  && {
    @media only screen and (min-width: 1800px) {
      font-size: 3.5rem;
    }
    @media (max-width: 1400px) {
      font-size: 3.5rem;
    }
  }
`;

export default function DropdownQuestion({
  errors,
  register,
  step,
  handleNextStep,
  title,
  image,
  name,
  options,
  watch,
  riskData,
  backStep,
  dropdown,
  setDropdown,
}) {
  const value = dropdown[name];
  const theme = useTheme();
  let isMobileSize = useContext(DeviceContext);
  const classes = isMobileSize ? useMobileStyles() : useStyles();
  const data = JSON.stringify(watch[name]);
  const dropDownIcon = styled(ExpandMoreIcon)`
    && {
      fill: ${theme.palette.secondary.main};
      font-size: 2rem;
      margin-right: 0.5rem;
      margin-top: 0.2rem;
    }
  `;

  let isDisabled = true;
  if (data * 1 >= 0) {
    isDisabled = false;
  }

  return (
    <>
      {isMobileSize ? (
        <GridWrapper
          item
          container
          justifyContent="space-between"
          direction="row"
          style={{ height: `${window.innerHeight}px` }}
        >
          <Grid item container direction="column" xs={12}>
            <Grid item container justifyContent="center">
              <Typography
                variant="h2"
                color="primary"
                className={classes.titleText}
              >
                <FormattedMessage {...messages?.riskQuestionTitile} />
              </Typography>
              <Grid
                item
                container
                xs={12}
                style={{
                  height: step < 3 ? 70 : "auto",
                  paddingTop: 18,
                  textAlign: "justify",
                }}
              >
                <Typography
                  variant="body1"
                  color="secondary"
                  className={classes.defaultText}
                  gutterBottom
                >
                  {`0${step}.`} {title}
                </Typography>
              </Grid>
            </Grid>

            <Grid item container>
              <TextField
                select
                name={name}
                value={value}
                className={classes.selectTextFieldQuestion}
                error={!!errors[`${name}`]}
                variant="outlined"
                SelectProps={{
                  classes: {
                    select:
                      value === "select"
                        ? classes.selectTextFieldBlank
                        : classes.selectTextFieldQuestion,
                  },
                  style: {
                    height: "30px",
                    color: color.grey,
                  },
                  IconComponent: dropDownIcon,
                }}
                {...register(`${name}`, {
                  required: true,
                  onChange: (e) => {
                    setDropdown({
                      ...dropdown,
                      [name]: e.target.value,
                    });
                  },
                })}
              >
                <MenuItemWrapper
                  key={"select"}
                  value={"select"}
                  theme={theme}
                  disabled
                >
                  {"Select"}
                </MenuItemWrapper>
                {options.map((option) => (
                  <MenuItemWrapper
                    key={option.key}
                    value={option.value}
                    theme={theme}
                  >
                    {option.label}
                  </MenuItemWrapper>
                ))}
              </TextField>
            </Grid>
          </Grid>

          <Grid
            item
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            xs={12}
          >
            <Grid item>
              <img
                src={image}
                alt={`image-${step}`}
                className={classes.imageQuestionnaire}
              />
            </Grid>
          </Grid>

          <Grid
            item
            container
            justifyContent="center"
            alignContent="center"
            xs={12}
          >
            <Grid
              item
              container
              justifyContent="center"
              alignContent="center"
              direction="column"
            >
              <Button
                variant="contained"
                color="primary"
                disabled={isDisabled}
                className={classes.nextButton}
                onClick={handleNextStep}
              >
                <Typography
                  algin="center"
                  className={classes.nomalText}
                  style={{ fontWeight: "bold" }}
                >
                  <FormattedMessage {...messages.next} />
                </Typography>
              </Button>
              <Grid item style={{ paddingTop: 10 }}></Grid>
              <Button
                variant="outlined"
                color="primary"
                onClick={backStep}
                className={classes.nextButton}
              >
                <Typography
                  algin="center"
                  style={{ fontSize: 14, fontWeight: "bold" }}
                >
                  <FormattedMessage {...messages.back} />
                </Typography>
              </Button>
            </Grid>
          </Grid>
        </GridWrapper>
      ) : (
        <Grid item container direction="column" style={{ paddingTop: 30 }}>
          <Grid item xs={12} container direction="column">
            <Typography variant="h1" algin="left" color="primary">
              <FormattedMessage {...messages?.riskQuestionnaire} />
            </Typography>
          </Grid>
          <Grid item xs={12} container direction="row">
            <Grid
              item
              xs={9}
              container
              direction="row"
              style={{
                alignContent: "flex-start",
              }}
            >
              <TypographyCustom
                variant="body2"
                color="secondary"
                style={{
                  textAlign: "left",
                  marginTop: 20,
                  marginBottom: 20,
                }}
              >
                {`0${step}.`} {title}
              </TypographyCustom>
              <TextField
                select
                name={name}
                value={value}
                style={{ width: "95%" }}
                error={!!errors[`${name}`]}
                variant="outlined"
                SelectProps={{
                  classes: {
                    select: classes.selectTextFieldQuestion,
                  },
                }}
                {...register(`${name}`, {
                  required: true,
                  onChange: (e) => {
                    setDropdown({
                      ...dropdown,
                      [name]: e.target.value,
                    });
                  },
                })}
              >
                <MenuItemWrapper key={"select"} value={"select"} theme={theme}>
                  {"Select"}
                </MenuItemWrapper>
                {options.map((option) => (
                  <MenuItemWrapper
                    key={option.key}
                    value={option.value}
                    theme={theme}
                  >
                    {option.label}
                  </MenuItemWrapper>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={3} container direction="row">
              <Grid item style={{ paddingTop: 30 }}>
                <img
                  src={image}
                  alt={`image-${step}`}
                  style={{ width: "100%" }}
                />
              </Grid>
              <Grid item container xs={12} style={{ alignContent: "flex-end" }}>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={isDisabled}
                  className={classes.nextButton}
                  onClick={handleNextStep}
                >
                  <Typography
                    algin="center"
                    style={{ fontSize: 24, fontWeight: "bold" }}
                  >
                    <FormattedMessage {...messages.next} />
                  </Typography>
                </Button>
                <Button
                  variant="outlined"
                  color="primary"
                  className={classes.backButton}
                  onClick={backStep}
                >
                  <Typography
                    algin="center"
                    style={{ fontSize: 24, fontWeight: "bold" }}
                  >
                    <FormattedMessage {...messages.back} />
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
}

DropdownQuestion.propTypes = {
  errors: PropTypes.object,
  register: PropTypes.func,
  step: PropTypes.number,
  handleNextStep: PropTypes.func,
  image: PropTypes.string,
  name: PropTypes.string,
  options: PropTypes.array,
  title: PropTypes.string,
  watch: PropTypes.object,
};
