import { Container } from '@material-ui/core';
import styled from 'styled-components';

const ContainerWrapper = styled(Container)`
  && {
    @media only screen and (min-width: 1800px) {
      min-height: 100vh;
    }
    @media (max-width: 1366px) {
      min-height: 100vh;
    }
  }
`;

export default ContainerWrapper;
