import "core-js/stable";
import "regenerator-runtime/runtime";
import smoothscroll from "smoothscroll-polyfill";

import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router/immutable";
import history from "utils/history";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/moment";
import "sanitize.css/sanitize.css";

// Import root app
import App from "containers/App";
// import global messages
import globalMessages from "containers/App/messages";

// Import Language Provider
import LanguageProvider from "containers/LanguageProvider";

// Load the .htaccess file
import "file-loader?name=.htaccess!./.htaccess"; // eslint-disable-line import/extensions
// Load web.config file
import "file-loader?name=web.config!./web.config";

import { theme } from "styles/theme";
import {
  setAppLoadingAction,
  setAppUpdateAction,
} from "containers/App/actions";
import configureStore from "./configureStore";

// Import i18n messages
import { translationMessages } from "./i18n";

// scroll polyfill
smoothscroll.polyfill();

// Create redux store with history
const initialState = {};
const store = configureStore(initialState, history);
const MOUNT_NODE = document.getElementById("attitude-to-risk");

const render = (messages) => {
  ReactDOM.render(
    <>
      <Provider store={store}>
        <LanguageProvider messages={messages}>
          <ConnectedRouter history={history}>
            <MuiThemeProvider theme={theme}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <App />
              </MuiPickersUtilsProvider>
            </MuiThemeProvider>
          </ConnectedRouter>
        </LanguageProvider>
      </Provider>
    </>,
    MOUNT_NODE
  );
};

if (module.hot) {
  module.hot.accept(["./i18n", "containers/App"], () => {
    ReactDOM.unmountComponentAtNode(MOUNT_NODE);
    render(translationMessages);
  });
}

// Chunked polyfill for browsers without Intl support
if (!window.Intl) {
  new Promise((resolve) => {
    resolve(import("intl"));
  })
    .then(() => Promise.all([import("intl/locale-data/jsonp/en.js")])) // eslint-disable-line prettier/prettier
    .then(() => render(translationMessages))
    .catch((err) => {
      throw err;
    });
} else {
  render(translationMessages);
}

// Install ServiceWorker and AppCache in the end since
// it's not most important operation and if main code fails,
// we do not want it installed
if (process.env.NODE_ENV === "production") {
  const runtime = require("offline-plugin/runtime"); // eslint-disable-line global-require
  runtime.install({
    onUpdating: () => {
      // window.console.log("onUpdating");
      // store.dispatch(
      //   setAppLoadingAction(true, globalMessages.appUpdatingMessage),
      // );
      // store.dispatch(setAppUpdateAction(true))
    },
    onUpdateReady: () => {
      // Tells to new SW to take control immediately
      store.dispatch(setAppUpdateAction(true));
      runtime.applyUpdate();
    },
    onUpdated: () => {
      // store.dispatch(setAppLoadingAction(true, globalMessages.appReloading));
      // setTimeout(() => {
      //   // store.dispatch(setAppLoadingAction(false));
      //   store.dispatch(setAppUpdateAction(true))
      //   // Reload the webpage to load into the new version
      //   // window.location.replace(`${window.location.pathname}`);
      // }, 1000);
    },
    onUpdateFailed: () => {
      store.dispatch(setAppUpdateAction(false));
    },
  });
}
