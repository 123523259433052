import React, { useContext } from "react";
import {
  Grid,
  Typography,
  Box,
  Button,
  Fade,
  CircularProgress,
} from "@material-ui/core";
import DoughnutChart from "../../../../components/DoughnutChart";
import { PropTypes } from "prop-types";
import { FormattedMessage } from "react-intl";
import messages from "../../messages";
import useStyles from "../styles";
import { useTheme } from "@material-ui/core/styles";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import DeviceContext from "components/DetectDevice";
import useMobileStyles from "../mobileStyles";
import {
  checkType,
  checkRiskProfile,
  checkAssumeMessages,
  checkSuggestMessages,
} from "helpers/checkProfile";

const summaryColor = ["#23366F", "#5495CF"];

export default function Summary({
  data,
  handleRetake,
  handleLoginZIO,
  isLoading,
  backStep,
  error,
}) {
  let isMobileSize = useContext(DeviceContext);
  const theme = useTheme();
  const classes = isMobileSize ? useMobileStyles() : useStyles();

  const isDisabled = data?.result?.RiskProfile ? false : true;
  const doughnutData = {
    ...data.result,
  };
  const labelData = [
    {
      label: "Equities",
      value: Math.round(doughnutData?.RiskyAsset * 1 * 100),
    },
    {
      label: "Bonds",
      value: Math.round(doughnutData?.NonRiskyAsset * 1 * 100),
    },
  ];
  const typeRiskProfile = Math.round(doughnutData?.RiskProfile * 1);
  return (
    <>
      {isMobileSize ? (
        <Grid item container direction="column">
          <Grid item>
            <Typography
              variant="h2"
              color="secondary"
              className={classes.titleText}
            >
              <FormattedMessage {...messages?.summaryTitle} />
            </Typography>
          </Grid>
          <Grid item>
            {isLoading ? (
              <Box
                zIndex="500"
                display="flex"
                alignItems="center"
                justifyContent="center"
                position="position"
                top="0"
                left="0"
                right="0"
                bottom="0"
                bgcolor="white"
                width="100%"
                height="100vh"
                borderRadius="16px"
              >
                <Fade in>
                  <Grid
                    spacing={2}
                    container
                    direction="column"
                    justifyContent="center"
                    align="center"
                  >
                    <Grid item>
                      <CircularProgress color="secondary" />
                      <Typography style={{ color: "#726F6F" }}>
                        <FormattedMessage {...messages.loading} />
                      </Typography>
                    </Grid>
                  </Grid>
                </Fade>
              </Box>
            ) : (
              <>
                <Grid item style={{ paddingTop: 50 }}>
                  <DoughnutChart
                    dataArray={labelData}
                    backgroundColorArray={summaryColor}
                    typeRiskProfile={checkType(typeRiskProfile)}
                    isMobileSize={isMobileSize}
                  />
                </Grid>
                <Grid
                  item
                  container
                  style={{ paddingTop: 30 }}
                  justifyContent="center"
                  alignItems="center"
                  spacing={4}
                >
                  {labelData &&
                    labelData.map((item, index) => (
                      <Grid item key={index}>
                        <Typography
                          variant="body2"
                          algin="left"
                          style={{
                            color: summaryColor[index],
                            fontWeight: "bold",
                            fontSize: 18,
                          }}
                        >
                          {item.value}%
                          <Box
                            width="57px"
                            height="3px"
                            style={{ backgroundColor: summaryColor[index] }}
                          />
                        </Typography>
                        <Typography
                          variant="body1"
                          algin="left"
                          style={{ color: summaryColor[index], fontSize: 12 }}
                        >
                          {item.label}
                        </Typography>
                      </Grid>
                    ))}
                </Grid>
                <Grid item style={{ paddingTop: 30 }}>
                  <Typography
                    variant="body1"
                    algin="left"
                    className={classes.nomalText}
                  >
                    {typeRiskProfile ? (
                      <>
                        <FormattedMessage
                          {...messages.summary}
                          values={{
                            value: <b>{checkRiskProfile(typeRiskProfile)}</b>,
                          }}
                        />
                        <FormattedMessage
                          {...checkAssumeMessages(typeRiskProfile)}
                        />
                      </>
                    ) : null}
                  </Typography>
                </Grid>
                <Grid item style={{ paddingTop: 20 }}>
                  <Typography
                    variant="body1"
                    algin="left"
                    className={classes.nomalText}
                  >
                    {typeRiskProfile ? (
                      <FormattedMessage
                        {...checkSuggestMessages(typeRiskProfile)}
                      />
                    ) : null}
                  </Typography>
                </Grid>

                <Grid
                  item
                  container
                  style={{
                    paddingTop: 20,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Grid item container xs={12} direction="row">
                    <Grid item container xs={1}></Grid>

                    <Grid
                      item
                      container
                      xs={3}
                      style={{ justifyContent: "flex-start" }}
                    >
                      <Button
                        variant="outlined"
                        color="primary"
                        style={{
                          width: "100%",
                          borderRadius: 20,
                          textTransform: "none",
                          fontWeight: 600,
                          backgroundColor: theme.palette.secondary.white,
                        }}
                        onClick={backStep}
                      >
                        <Typography
                          algin="center"
                          style={{ fontSize: 12, fontWeight: "bold" }}
                        >
                          <FormattedMessage {...messages.back} />
                        </Typography>
                      </Button>
                    </Grid>

                    <Grid
                      item
                      container
                      xs
                      style={{ justifyContent: "flex-end" }}
                    >
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={handleRetake}
                        style={{
                          minWidth: 200,
                          borderRadius: 20,
                        }}
                        className={classes.summaryButton}
                      >
                        <Typography
                          variant="body1"
                          className={classes.customTextSize}
                        >
                          <FormattedMessage {...messages.retakeQuestion} />
                        </Typography>
                      </Button>
                    </Grid>

                    <Grid item container xs={1}></Grid>
                  </Grid>

                  <Grid
                    item
                    container
                    xs={12}
                    style={{
                      paddingTop: 10,
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: 20,
                    }}
                  >
                    <Grid item container xs={1}></Grid>
                    <Grid item container xs={10}>
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={handleLoginZIO}
                        fullWidth
                        style={{
                          borderRadius: 20,
                        }}
                      >
                        <Typography
                          variant="body1"
                          className={classes.customTextSize}
                        >
                          <FormattedMessage {...messages.login} />

                          <ArrowForwardIosIcon
                            style={{
                              fontSize: 14,
                              marginBottom: 2,
                              marginLeft: 5,
                            }}
                          />
                        </Typography>
                      </Button>
                    </Grid>
                    <Grid item container xs={1}></Grid>
                  </Grid>
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      ) : (
        <>
          <Grid item container direction="column" style={{ paddingTop: 20 }}>
            <Grid item xs={12} container direction="column">
              <Typography variant="h1" algin="left" color="primary">
                <FormattedMessage {...messages.summaryTitle} />
              </Typography>
            </Grid>

            <Grid item xs={12} container direction="row">
              {isLoading ? (
                <Box
                  zIndex="500"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  position="relative"
                  top="0"
                  left="0"
                  right="0"
                  bottom="0"
                  width="100%"
                  height="600px"
                  borderRadius="16px"
                >
                  <Fade in>
                    <Grid
                      spacing={2}
                      container
                      direction="column"
                      justifyContent="center"
                      align="center"
                    >
                      <Grid item>
                        <CircularProgress color="secondary" />
                        <Typography style={{ color: "#726F6F" }}>
                          <FormattedMessage {...messages.loading} />
                        </Typography>
                      </Grid>
                    </Grid>
                  </Fade>
                </Box>
              ) : (
                <>
                  <Grid item xs={6} style={{ paddingTop: 50 }}>
                    <DoughnutChart
                      dataArray={labelData}
                      backgroundColorArray={summaryColor}
                      typeRiskProfile={checkType(typeRiskProfile)}
                      isMobileSize={isMobileSize}
                    />
                  </Grid>

                  <Grid
                    item
                    xs={6}
                    container
                    direction="column"
                    spacing={1}
                    style={{ overflow: "hidden", paddingTop: 30 }}
                  >
                    <Grid item container spacing={4}>
                      {labelData &&
                        labelData.map((item, index) => (
                          <Grid item key={index}>
                            <Typography
                              variant="body2"
                              algin="left"
                              style={{
                                color: summaryColor[index],
                                fontWeight: "bold",
                              }}
                            >
                              {item.value}%
                              <Box
                                width="100px"
                                height="8px"
                                style={{ backgroundColor: summaryColor[index] }}
                              />
                            </Typography>
                            <Typography
                              variant="body1"
                              algin="left"
                              style={{ color: summaryColor[index] }}
                            >
                              {item.label}
                            </Typography>
                          </Grid>
                        ))}

                      <Grid item style={{ textAlign: "left" }}>
                        <Typography
                          variant="body1"
                          algin="left"
                          color="secondary"
                          style={{ lineHeight: 1.2 }}
                        >
                          {typeRiskProfile ? (
                            <>
                              <FormattedMessage
                                {...messages.summary}
                                values={{
                                  value: (
                                    <b>{checkRiskProfile(typeRiskProfile)}</b>
                                  ),
                                }}
                              />

                              <FormattedMessage
                                {...checkAssumeMessages(typeRiskProfile)}
                              />
                            </>
                          ) : null}
                        </Typography>
                      </Grid>

                      <Grid item style={{ textAlign: "left" }}>
                        <Typography
                          variant="body1"
                          algin="left"
                          color="secondary"
                          style={{ lineHeight: 1.2 }}
                        >
                          {typeRiskProfile ? (
                            <FormattedMessage
                              {...checkSuggestMessages(typeRiskProfile)}
                            />
                          ) : null}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            container
            style={{ paddingTop: 10 }}
            direction="row"
          >
            <Grid item xs={6}></Grid>
            <Grid item xs={6} container>
              <Grid
                item
                container
                xs={12}
                style={{ alignItems: "center", justifyContent: "center" }}
              >
                <Grid
                  item
                  container
                  xs={4}
                  style={{ justifyItems: "flex-start" }}
                >
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={backStep}
                    disabled={error.isError}
                    style={{
                      width: "95%",
                      borderRadius: 20,
                    }}
                  >
                    <Typography
                      algin="center"
                      style={{ fontSize: 20, fontWeight: "bold" }}
                    >
                      <FormattedMessage {...messages.back} />
                    </Typography>
                  </Button>
                </Grid>

                <Grid item container xs style={{ justifyItems: "flex-end" }}>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleRetake}
                    style={{
                      borderRadius: 20,
                    }}
                    fullWidth
                    disabled={error.isError}
                  >
                    <Typography
                      variant="body1"
                      style={{ color: "#FFFFFF", fontSize: 20 }}
                    >
                      <FormattedMessage {...messages.retakeQuestion} />
                    </Typography>
                  </Button>
                </Grid>
              </Grid>

              <Grid item container xs={12} style={{ paddingTop: 15 }}>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleLoginZIO}
                  disabled={error.isError}
                  fullWidth
                  style={{
                    borderRadius: 20,
                  }}
                >
                  <Typography
                    variant="body1"
                    style={{ color: "#FFFFFF", fontSize: 20 }}
                  >
                    <FormattedMessage {...messages.login} />
                    {"   "}
                    <ArrowForwardIosIcon
                      style={{ fontSize: 24, marginBottom: 5, marginLeft: 5 }}
                    />
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
}

Summary.propTypes = {
  data: PropTypes.object,
  typeRiskProfile: PropTypes.string,
};
