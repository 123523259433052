import React, { forwardRef, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Grid, Button, IconButton, Container } from "@material-ui/core";
import { TextField } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import styled from "styled-components";
import {
  NumberFormatCustom,
  MenuItemWrapper,
  GridWrapper,
  CustomPopover,
} from "components";
import { Typography } from "@material-ui/core";
import { useForm } from "react-hook-form";
import { isFilledAllFields, convertToNumberWithoutCommas } from "utils/form";
import useStyles from "./../styles";
import ATTITUDE_BANNER from "images/img_risktool.png";
import EditIcon from "@material-ui/icons/Edit";
import { color } from "styles/color";
import _ from "lodash";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import InputAdornment from "@material-ui/core/InputAdornment";
import messages from "../../messages";
import { FormattedMessage } from "react-intl";

const PlanningForm = forwardRef((props, ref) => {
  const {
    handleSubmitForm,
    initialValues,
    resources,
    optionalResources,
    intl,
  } = props;
  const theme = useTheme();
  const classes = useStyles();
  const dropDownIcon = styled(ExpandMoreIcon)`
    && {
      fill: ${theme.palette.secondary.main};
      font-size: 2rem;
    }
  `;

  const [disabledInflation, setDisabledInflation] = useState(true);
  const [disabledGrowth, setDisabledGrowth] = useState(true);
  const [inflationRatePop, setinflationRatePop] = useState("");
  const [interestRatePop, setinterestRatePop] = useState("");

  const [dropdown, setDropdown] = useState({
    retirementCountry: initialValues?.country
      ? initialValues?.country
      : "select",
    currency: initialValues?.currency ? initialValues?.currency : "select",
    currentAge: initialValues?.currentAge
      ? initialValues?.currentAge
      : "select",
    retirementAge: initialValues?.retirementAge
      ? initialValues?.retirementAge
      : "select",
    lifeStyle: initialValues?.lifestyle ? initialValues?.lifestyle : "select",
  });

  const editInflation = () => {
    setDisabledInflation(!disabledInflation);
  };
  const editGrowth = () => {
    setDisabledGrowth(!disabledGrowth);
  };

  const {
    handleSubmit,
    register,
    getValues,
    watch,
    setValue,
    formState: { errors, isDirty },
  } = useForm({
    defaultValues: initialValues,
  });

  const watchAllFields = watch();

  const COUNTRIES = [];
  const CURRENCIES = [];
  const purchasePowerParity =
    _.find(resources, { key: "nc_purchase_power_parity" })?.value?.data || [];
  const LIFESTYLE =
    _.find(resources, { key: "nc_lifestyle_replacement" })?.value?.data || [];

  optionalResources.forEach((item) => {
    if (item.group === "goal_retirement_country") {
      purchasePowerParity.forEach((country) => {
        if (country.key === item.code) {
          COUNTRIES.push({
            value: item.en,
            label: item.en,
            inflationRate: country.inflationRate,
            interestRate: country.interestRate,
          });
        }
      });
    }
    if (item.group === "goal_currency") {
      CURRENCIES.push({
        value: item.code,
        label: item.en,
      });
    }
  });

  const countryInflationRate = (
    COUNTRIES.find((item) => item.value === getValues("country"))
      ?.inflationRate * 100
  ).toFixed(2);
  const countryInterestRate = (
    COUNTRIES.find((item) => item.value === getValues("country"))
      ?.interestRate * 100
  ).toFixed(2);

  const countries = COUNTRIES.sort(function(a, b) {
    var nameA = a.label.toUpperCase();
    var nameB = b.label.toUpperCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });

  const currencies = CURRENCIES.sort(function(a, b) {
    var nameA = a.label.toUpperCase();
    var nameB = b.label.toUpperCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });

  const ERROR_MESSAGE = {
    E001: intl.formatMessage(messages.error1),
    E002: intl.formatMessage(messages.error2),
    E003: intl.formatMessage(messages.error3, {
      currentAge: getValues("currentAge") * 1 + 7,
    }),
    E004: intl.formatMessage(messages.error4),
    E005: intl.formatMessage(messages.error5, {
      monthlyIncome:
        convertToNumberWithoutCommas(getValues("monthlyIncome")) * 1 - 1,
    }),
    E006: intl.formatMessage(messages.error6, {
      value:
        convertToNumberWithoutCommas(getValues("monthlyIncome")) * 1 -
        convertToNumberWithoutCommas(getValues("monthlyExpense")) * 1,
    }),
    E007: intl.formatMessage(messages.error7),
    E008: intl.formatMessage(messages.error8, {
      min: (countryInflationRate * 0.5).toFixed(2),
      max: (countryInflationRate * 1.5).toFixed(2),
    }),
    E009: intl.formatMessage(messages.error9, {
      min: (countryInterestRate * 0.5).toFixed(2),
      max: (countryInterestRate * 1.5).toFixed(2),
    }),
    E010: intl.formatMessage(messages.error10),
    E011: intl.formatMessage(messages.error11),
  };

  useEffect(() => {
    if (disabledInflation) {
      setinflationRatePop(
        (
          countries.find((item) => item.value === dropdown.retirementCountry)
            ?.inflationRate * 100
        ).toFixed(2)
      );
    }
    if (disabledGrowth) {
      setinterestRatePop(
        (
          countries.find((item) => item.value === dropdown.retirementCountry)
            ?.interestRate * 100
        ).toFixed(2)
      );
    }
  }, [watchAllFields]);

  const onSubmit = (data) => {
    handleSubmitForm(data);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ overflow: "hidden" }}>
      <Container style={{ padding: 0 }}>
        <GridWrapper
          theme={theme}
          style={{
            backgroundColor: "#C2C9D5",
            padding: "0 0 32px 0",
          }}
          container
        >
          <Grid
            item
            xs={8}
            container
            direction="row"
            style={{
              alignContent: "flex-start",
              paddingLeft: 70,
              paddingTop: 30,
            }}
          >
            <Grid item container>
              <Typography
                color="primary"
                variant="h3"
                style={{
                  fontSize: "35pt",
                  letterSpacing: -1.5,
                }}
              >
                Attitude to Risk Questionnaire
              </Typography>
            </Grid>
            <Typography
              variant="h6"
              className={classes.defaultText}
              style={{ marginTop: 20, fontWeight: "bold", paddingBottom: 10 }}
              gutterBottom
            >
              How much do I need to save ?
            </Typography>

            <Typography
              variant="body1"
              className={classes.defaultText}
              gutterBottom
              style={{ paddingBottom: 10 }}
            >
              I would like to retire in
              <TextField
                conponent="span"
                variant="outlined"
                select
                name="country"
                style={{
                  margin: theme.spacing(0, 1),
                  minWidth: "100px",
                  paddingBottom: 10,
                }}
                defaultValue={""}
                value={dropdown.retirementCountry}
                error={!!errors.country}
                inputRef={ref}
                SelectProps={{
                  classes: {
                    select:
                      dropdown.retirementCountry === "select"
                        ? classes.placeholderTextField
                        : classes.selectTextField,
                  },
                  IconComponent: dropDownIcon,
                }}
                {...register("country", {
                  required: true,
                  onChange: (e) =>
                    setDropdown({
                      ...dropdown,
                      retirementCountry: e.target.value,
                    }),
                })}
              >
                <MenuItemWrapper
                  style={{ fontSize: "14pt", padding: "0px 0px 0px 10px" }}
                  key={"select"}
                  value={"select"}
                  theme={theme}
                  height={40}
                  disabled
                >
                  Country
                </MenuItemWrapper>
                {countries.map((country) => (
                  <MenuItemWrapper
                    style={{ fontSize: "14pt", padding: "0px 0px 0px 10px" }}
                    key={country.key}
                    value={country.value}
                    theme={theme}
                    height={40}
                  >
                    {country.label}
                  </MenuItemWrapper>
                ))}
              </TextField>
              and would like to save in {<br />}
              <TextField
                select
                variant="outlined"
                name="currency"
                style={{ marginRight: 8, minWidth: 100 }}
                defaultValue={""}
                value={dropdown.currency}
                error={!!errors.currency}
                inputRef={ref}
                SelectProps={{
                  classes: {
                    select:
                      dropdown.currency === "select"
                        ? classes.placeholderTextField
                        : classes.selectTextField,
                  },
                  IconComponent: dropDownIcon,
                }}
                {...register("currency", {
                  required: true,
                  onChange: (e) =>
                    setDropdown({
                      ...dropdown,
                      currency: e.target.value,
                    }),
                })}
              >
                <MenuItemWrapper
                  style={{ fontSize: "14pt", padding: "0px 0px 0px 10px" }}
                  key={"select"}
                  value={"select"}
                  theme={theme}
                  height={40}
                  disabled
                >
                  Currency
                </MenuItemWrapper>
                {currencies.map((currency) => (
                  <MenuItemWrapper
                    style={{ fontSize: "14pt", padding: "0px 0px 0px 10px" }}
                    key={currency.key}
                    value={currency.value}
                    theme={theme}
                  >
                    {currency.label}
                  </MenuItemWrapper>
                ))}
              </TextField>
              Currency.
            </Typography>

            <Typography
              variant="body1"
              className={classes.defaultText}
              gutterBottom
              style={{ paddingBottom: 10 }}
            >
              I am
              <TextField
                variant="outlined"
                select
                name="currentAge"
                style={{ margin: theme.spacing(0, 1), minWidth: "70px" }}
                defaultValue={""}
                value={dropdown.currentAge}
                error={!!errors.currentAge}
                inputRef={ref}
                SelectProps={{
                  classes: {
                    select:
                      dropdown.currentAge === "select"
                        ? classes.placeholderTextField
                        : classes.selectTextField,
                  },
                  IconComponent: dropDownIcon,
                }}
                {...register("currentAge", {
                  required: true,
                  onChange: (e) =>
                    setDropdown({
                      ...dropdown,
                      currentAge: e.target.value,
                    }),
                })}
              >
                <MenuItemWrapper
                  style={{ fontSize: "14pt", padding: "0px 0px 0px 10px" }}
                  key={"select"}
                  value={"select"}
                  theme={theme}
                  height={40}
                  disabled
                >
                  Age
                </MenuItemWrapper>
                {Array.from({ length: 76 - 18 + 1 }, (_, i) => (
                  <MenuItemWrapper
                    key={i}
                    value={i + 18}
                    theme={theme}
                    style={{ fontSize: "14pt", padding: "0px 0px 0px 10px" }}
                  >
                    {i + 18}
                  </MenuItemWrapper>
                ))}
              </TextField>
              years old and would like to retire when I am
              <TextField
                variant="outlined"
                select
                name="retirementAge"
                style={{ margin: theme.spacing(0, 1), minWidth: "80px" }}
                defaultValue={""}
                value={dropdown.retirementAge}
                error={!!errors.retirementAge}
                inputRef={ref}
                SelectProps={{
                  classes: {
                    select:
                      dropdown.retirementAge === "select"
                        ? classes.placeholderTextField
                        : classes.selectTextField,
                  },
                  IconComponent: dropDownIcon,
                }}
                {...register("retirementAge", {
                  required: true,
                  onChange: (e) =>
                    setDropdown({
                      ...dropdown,
                      retirementAge: e.target.value,
                    }),
                })}
              >
                <MenuItemWrapper
                  style={{ fontSize: "14pt", padding: "0px 0px 0px 10px" }}
                  key={"select"}
                  value={"select"}
                  theme={theme}
                  height={40}
                  disabled
                >
                  Age
                </MenuItemWrapper>
                {Array.from(
                  {
                    length:
                      getValues("currentAge") > 0
                        ? 83 - getValues("currentAge") - 7 + 1
                        : 0,
                  },
                  (_, i) => (
                    <MenuItemWrapper
                      style={{ fontSize: "14pt", padding: "0px 0px 0px 10px" }}
                      key={i}
                      value={getValues("currentAge") + 7 + i}
                      theme={theme}
                    >
                      {getValues("currentAge") + 7 + i}
                    </MenuItemWrapper>
                  )
                )}
              </TextField>
            </Typography>

            <Typography
              variant="body1"
              className={classes.defaultText}
              gutterBottom
              style={{ paddingBottom: 10 }}
            >
              My monthly income is{" "}
              {`${dropdown.currency === "select" ? "" : dropdown.currency} `}
              <TextField
                variant="outlined"
                name="monthlyIncome"
                defaultValue={
                  initialValues?.monthlyIncome
                    ? initialValues.monthlyIncome
                    : ""
                }
                placeholder="1,000"
                error={!!errors.monthlyIncome}
                style={{
                  margin: "0 8px 10px 0",
                  backgroundColor: "white",
                  borderRadius: "4px",
                }}
                inputProps={{
                  autocomplete: "off",
                  className: classes.customInput,
                  style: {
                    color: errors.monthlyIncome ? theme.palette.error.main : "",
                  },
                }}
                // eslint-disable-next-line react/jsx-no-duplicate-props
                InputProps={{
                  inputComponent: NumberFormatCustom,
                  endAdornment: errors.monthlyIncome ? (
                    <>
                      <CustomPopover
                        errorMessage={ERROR_MESSAGE.E004}
                        id={"monthlyIncome"}
                      />
                    </>
                  ) : null,
                }}
                inputRef={ref}
                {...register("monthlyIncome", {
                  required: true,
                  validate: () => {
                    const income = convertToNumberWithoutCommas(
                      getValues("monthlyIncome")
                    );
                    if (income < 500 || income > 9999999999) {
                      return ERROR_MESSAGE.E004;
                    }
                  },
                  // onChange: (e) => {
                  //   setValue("monthlyIncome", convertToNumberWithoutCommas(e.target.value));
                  // },
                })}
              />
              and I spend{" "}
              {`${dropdown.currency === "select" ? "" : dropdown.currency} `}
              <TextField
                variant="outlined"
                name="monthlyExpense"
                style={{
                  margin: "0 8px 0 0",
                  backgroundColor: "white",
                  borderRadius: "4px",
                }}
                defaultValue={
                  initialValues?.monthlyExpense
                    ? initialValues.monthlyExpense
                    : ""
                }
                error={!!errors.monthlyExpense}
                placeholder="1,000"
                inputRef={ref}
                inputProps={{
                  autocomplete: "off",
                  className: classes.customInput,
                  style: {
                    color: errors.monthlyExpense
                      ? theme.palette.error.main
                      : "",
                  },
                }}
                InputProps={{
                  inputComponent: NumberFormatCustom,
                  endAdornment: errors.monthlyExpense ? (
                    <>
                      <CustomPopover
                        errorMessage={ERROR_MESSAGE.E005}
                        id={"monthlyExpense"}
                      />
                    </>
                  ) : null,
                }}
                {...register("monthlyExpense", {
                  required: true,
                  validate: () => {
                    const income = convertToNumberWithoutCommas(
                      getValues("monthlyIncome")
                    );
                    const expense = convertToNumberWithoutCommas(
                      getValues("monthlyExpense")
                    );
                    if (expense > income - 1) {
                      return ERROR_MESSAGE.E005;
                    }
                  },
                  // onChange: (e) => {
                  //   setValue("monthlyExpense", convertToNumberWithoutCommas(e.target.value));
                  // },
                })}
              />
              every month.
            </Typography>

            <Typography
              variant="body1"
              className={classes.defaultText}
              gutterBottom
              style={{ paddingBottom: 10 }}
            >
              So far I have saved{" "}
              {`${dropdown.currency === "select" ? "" : dropdown.currency} `}
              <TextField
                variant="outlined"
                error={!!errors.currentSavings}
                style={{
                  margin: "0 8px 10px 0",
                  backgroundColor: "white",
                  borderRadius: "4px",
                }}
                defaultValue={
                  initialValues?.currentSavings
                    ? initialValues.currentSavings
                    : ""
                }
                placeholder="1,000"
                inputProps={{
                  autocomplete: "off",
                  className: classes.customInput,
                  style: {
                    color: errors.currentSavings
                      ? theme.palette.error.main
                      : "",
                    maxWidth: "205px",
                  },
                }}
                // eslint-disable-next-line react/jsx-no-duplicate-props
                InputProps={{
                  inputComponent: NumberFormatCustom,
                  endAdornment: errors.currentSavings ? (
                    <>
                      <CustomPopover
                        errorMessage={ERROR_MESSAGE.E007}
                        id={"currentSavings"}
                      />
                    </>
                  ) : null,
                }}
                inputRef={ref}
                {...register("currentSavings", {
                  required: true,
                  validate: () => {
                    const saved = convertToNumberWithoutCommas(
                      getValues("currentSavings")
                    );
                    if (saved > 9999999999999) {
                      return ERROR_MESSAGE.E007;
                    }
                    // setValue("currentSavings", saved);
                  },
                  // onChange: (e) => {
                  //   setValue("currentSavings", convertToNumberWithoutCommas(e.target.value));
                  // },
                })}
              />
              and will save
              {`${dropdown.currency === "select" ? "" : dropdown.currency} `}
              <TextField
                variant="outlined"
                name="plannedSavings"
                style={{
                  margin: "0 8px 0 0",
                  backgroundColor: "white",
                  borderRadius: "4px",
                }}
                placeholder="1,000"
                defaultValue={
                  initialValues?.plannedSavings
                    ? initialValues.plannedSavings
                    : ""
                }
                error={!!errors.plannedSavings}
                inputProps={{
                  autocomplete: "off",
                  className: classes.customInput,
                  style: {
                    color: errors.plannedSavings
                      ? theme.palette.error.main
                      : "",
                  },
                }}
                inputRef={ref}
                InputProps={{
                  inputComponent: NumberFormatCustom,
                  endAdornment: errors.plannedSavings ? (
                    <>
                      <CustomPopover
                        errorMessage={ERROR_MESSAGE.E006}
                        id={"plannedSavings"}
                      />
                    </>
                  ) : null,
                }}
                {...register("plannedSavings", {
                  required: true,
                  validate: () => {
                    const income = convertToNumberWithoutCommas(
                      getValues("monthlyIncome")
                    );
                    const expense = convertToNumberWithoutCommas(
                      getValues("monthlyExpense")
                    );
                    const planed = convertToNumberWithoutCommas(
                      getValues("plannedSavings")
                    );
                    if (planed > income - expense || planed < 0) {
                      return ERROR_MESSAGE.E006;
                    }
                  },
                  // onChange: (e) => {
                  //   setValue("plannedSavings", convertToNumberWithoutCommas(e.target.value));
                  // },
                })}
              />
              monthly for my retirement.
            </Typography>

            <Typography
              variant="body1"
              className={classes.defaultText}
              gutterBottom
              style={{ paddingBottom: 10 }}
            >
              When I retire I would like my lifestyle to be
              <TextField
                variant="outlined"
                select
                name="lifestyle"
                style={{ margin: theme.spacing(0, 1), minWidth: "120px" }}
                defaultValue={""}
                value={dropdown.lifeStyle}
                error={!!errors.lifestyle}
                inputRef={ref}
                SelectProps={{
                  classes: {
                    select:
                      dropdown.lifeStyle === "select"
                        ? classes.placeholderTextField
                        : classes.selectTextField,
                  },
                  IconComponent: dropDownIcon,
                }}
                {...register("lifestyle", {
                  required: true,
                  onChange: (e) =>
                    setDropdown({
                      ...dropdown,
                      lifeStyle: e.target.value,
                    }),
                })}
              >
                <MenuItemWrapper
                  style={{ fontSize: "14pt", padding: "0px 0px 0px 10px" }}
                  key={"select"}
                  value={"select"}
                  theme={theme}
                  height={40}
                  disabled
                >
                  Lifestyle
                </MenuItemWrapper>

                {LIFESTYLE.map((lifestyle) => (
                  <MenuItemWrapper
                    style={{ fontSize: "14pt", padding: "0px 0px 0px 10px" }}
                    key={lifestyle.key}
                    value={lifestyle.description}
                    theme={theme}
                  >
                    {lifestyle.description}
                  </MenuItemWrapper>
                ))}
              </TextField>
            </Typography>

            <Grid item container style={{ paddingTop: 35 }}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={!isDirty || !isFilledAllFields(watchAllFields)}
                style={{
                  minWidth: 200,
                  width: 380,
                  height: 50,
                  borderRadius: 30,
                  fontSize: 20,
                  fontWeight: 700,
                  textTransform: "none",
                }}
              >
                <Typography
                  variant="body1"
                  style={{
                    color:
                      !isDirty || !isFilledAllFields(watchAllFields)
                        ? "rgba(0, 0, 0, 0.26)"
                        : color.white,
                    fontSize: "24px",
                    fontWeight: "bold",
                  }}
                >
                  <FormattedMessage {...messages.next} />
                </Typography>
              </Button>
            </Grid>
          </Grid>

          <Grid item container xs={4} direction="row">
            <Grid
              item
              container
              style={{
                backgroundColor: "white",
                marginTop: 100,
                maxWidth: "85%",
                height: 250,
                padding: "20px 20px 0px 20px",
              }}
              xs={12}
            >
              <Grid item container xs={12}>
                <Typography
                  variant="h1"
                  className={classes.defaultText}
                  style={{ fontWeight: "bold" }}
                >
                  Assumed values
                </Typography>
              </Grid>
              <Grid item container xs={12}>
                <Grid item xs={6}>
                  <Typography variant="h1" className={classes.defaultText}>
                    Inflation
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    name="inflationRate"
                    disabled={disabledInflation}
                    value={inflationRatePop}
                    error={!!errors.inflationRate}
                    inputProps={{
                      autocomplete: "off",
                      className: classes.customInputBox,
                      style: {
                        color: errors.inflationRate
                          ? theme.palette.error.main
                          : "",
                        textAlign: "center",
                      },
                    }}
                    // eslint-disable-next-line react/jsx-no-duplicate-props
                    InputProps={{
                      inputComponent: NumberFormatCustom,
                      endAdornment: errors.inflationRate ? (
                        <>
                          <CustomPopover
                            errorMessage={ERROR_MESSAGE.E008}
                            id={"inflationRate"}
                          />
                        </>
                      ) : (
                        <InputAdornment position="end">
                          <Typography
                            variant="h1"
                            className={classes.defaultText}
                          >
                            %
                          </Typography>
                        </InputAdornment>
                      ),
                    }}
                    inputRef={ref}
                    {...register("inflationRate", {
                      required: true,
                      validate: () => {
                        const inflationRate =
                          (getValues("inflationRate") * 1).toFixed(2) * 1;
                        const min =
                          (countryInflationRate * 1 * 0.5).toFixed(2) * 1;
                        const max =
                          (countryInflationRate * 1 * 1.5).toFixed(2) * 1;
                        if (inflationRate < min || inflationRate > max) {
                          return ERROR_MESSAGE.E008;
                        }
                      },
                      onChange: (e) =>
                        setinflationRatePop(
                          convertToNumberWithoutCommas(e.target.value)
                        ),
                    })}
                  />
                  <IconButton
                    color="primary"
                    onClick={editInflation}
                    size="small"
                  >
                    <EditIcon fontSize="small" />
                  </IconButton>
                </Grid>
              </Grid>

              <Grid item container xs={12}>
                <Grid item xs={6}>
                  <Typography variant="h1" className={classes.defaultText}>
                    Growth Rate
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    name="growthRate"
                    disabled={disabledGrowth}
                    value={interestRatePop}
                    error={!!errors.growthRate}
                    inputProps={{
                      autocomplete: "off",
                      className: classes.customInputBox,
                      style: {
                        color: errors.growthRate
                          ? theme.palette.error.main
                          : "",
                        textAlign: "center",
                      },
                    }}
                    // eslint-disable-next-line react/jsx-no-duplicate-props
                    InputProps={{
                      inputComponent: NumberFormatCustom,
                      endAdornment: errors.growthRate ? (
                        <>
                          <CustomPopover
                            errorMessage={ERROR_MESSAGE.E009}
                            id={"inflationRate"}
                          />
                        </>
                      ) : (
                        <InputAdornment position="end">
                          <Typography
                            variant="h1"
                            className={classes.defaultText}
                          >
                            %
                          </Typography>
                        </InputAdornment>
                      ),
                    }}
                    inputRef={ref}
                    {...register("growthRate", {
                      required: true,
                      validate: () => {
                        const interestRate =
                          (getValues("growthRate") * 1).toFixed(2) * 1;
                        const min = (countryInterestRate * 0.5).toFixed(2) * 1;
                        const max = (countryInterestRate * 1.5).toFixed(2) * 1;
                        if (interestRate < min || interestRate > max) {
                          return ERROR_MESSAGE.E009;
                        }
                      },
                      onChange: (e) =>
                        setinterestRatePop(
                          convertToNumberWithoutCommas(e.target.value)
                        ),
                    })}
                  />
                  <IconButton color="primary" onClick={editGrowth} size="small">
                    <EditIcon fontSize="small" />
                  </IconButton>
                </Grid>
              </Grid>

              <Grid item container xs={12}>
                <Typography
                  variant="body2"
                  color="secondary"
                  style={{
                    fontSize: "12pt",
                    lineHeight: "1.2",
                  }}
                >
                  <FormattedMessage {...messages.assumedNote} />
                </Typography>
              </Grid>
            </Grid>

            <Grid
              item
              container
              style={{
                alignContent: "flex-end",
                marginLeft: -120,
                marginTop: 20,
              }}
              xs={12}
            >
              <img src={ATTITUDE_BANNER} alt="retirement" width={350} />
            </Grid>
          </Grid>
        </GridWrapper>
      </Container>
    </form>
  );
});

PlanningForm.propTypes = {
  handleSubmitForm: PropTypes.func,
  inputData: PropTypes.object,
};

export default PlanningForm;
