import React from "react";
import Chart from "chart.js/auto";
import { Bar } from "react-chartjs-2";
import { Grid } from "@material-ui/core";

export default function BarChart({
  dataArray,
  backgroundColorArray,
  YMin,
  YMax,
}) {
  const labels = dataArray?.map((data) => data.label);
  const datas = dataArray?.map((data) => data.value);

  const data = {
    labels: labels,
    datasets: [
      {
        data: datas,
        backgroundColor: backgroundColorArray,
        borderColor: backgroundColorArray,
        barThickness: 50,
      },
    ],
    options: {
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          titleFont: { weight: "regular" },
          callbacks: {
            title: function(tooltipItems) {
              return `${tooltipItems[0].formattedValue}%`;
            },
            label: function() {
              return null;
            },
          },
        },
      },
      scales: {
        y: {
          min: YMin,
          max: YMax,
          grid: {
            drawBorder: false,
            color: function(context) {
              if (context.tick.value > 0) {
                return "#ffffff";
              } else if (context.tick.value < 0) {
                return "#ffffff";
              }

              return "#18B9B6";
            },
          },
          ticks: {
            callback: function(value) {
              return `${value}%`;
            },
          },
        },
        x: {
          grid: {
            display: false,
            drawBorder: false,
          },
        },
      },
    },
  };
  return <Bar data={data} options={data.options} />;
}
