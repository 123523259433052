import { call, put, takeLatest, all } from "redux-saga/effects";
import { dispatchRequest } from "helpers/request";
import { GET_VERIFY_TOKEN_ACTION } from "./constants";
import { api, config } from "environments";
import { setVerifyTokenAction } from "./actions";

export function* getTokenVerify(action) {
  try {
    const { payload } = action;
    const input = {
      token: payload,
    };

    const url = `${api.apiHost}/api/v1/verify-token`;
    const requestOpt = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-TENANT-ID": config.tenantId,
        "Ocp-Apim-Subscription-Key": config.apiSubKey,
      },
      body: JSON.stringify(input),
    };
    const response = yield call(dispatchRequest, url, requestOpt);
    if (response) {
      yield put(setVerifyTokenAction(response));
    }
  } catch (err) {
    console.log(err);
  } finally {
  }
}

export default function* appSaga() {
  yield all([takeLatest(GET_VERIFY_TOKEN_ACTION, getTokenVerify)]);
}
